import { Grid, Typography } from '@material-ui/core';
import { ListSurvey, SurveyStatus } from '@gts-ns/utils';
import { SurveyCard } from './SurveyCard';

interface Props {
  surveys: Array<ListSurvey>;
  changeStatus: (surveyId: string, status: SurveyStatus) => void;
  showSurveyEdit: (surveyId: string) => void;
  showSurveyResults: (surveyId: string) => void;
  showSurveyQr: (surveyId: string) => void;
}

export const SurveyList = ({
  surveys,
  changeStatus,
  showSurveyEdit,
  showSurveyResults,
  showSurveyQr,
}: Props) => {
  if (surveys.length === 0) {
    return <Typography variant="subtitle1">Keine Umfragen</Typography>;
  } else {
    return (
      <Grid container spacing={2}>
        {surveys.map((survey) => (
          <Grid item xs={12} md={6} key={survey.surveyId}>
            <SurveyCard
              survey={survey}
              onChangeStatus={() =>
                changeStatus(survey.surveyId, survey.status)
              }
              showSurveyEdit={() => showSurveyEdit(survey.surveyId)}
              showSurveyResults={() => showSurveyResults(survey.surveyId)}
              showSurveyQR={() => showSurveyQr(survey.surveyId)}
            />
          </Grid>
        ))}
      </Grid>
    );
  }
};
