import { serverComm } from '@gts-ns/ui';
import { GetSurveyResultsResponse, SURVEY_RESULTS } from '@gts-ns/utils';
import { getDate, getDay, getHours, parseISO } from 'date-fns';
import {
  getErrorModalMessage,
  sendErrorToServer,
  serverRequestFailed,
  serverRequestSucceeded,
  showError,
  startServerRequest,
} from '@gts-common/client';
import {
  Actions,
  GOT_SURVEY_RESULTS,
  GotSurveyResultsAction,
  Thunk,
} from '../reduxActionTypes';
import { SurveyResultsQuestion } from '../../types';
import { getOperationFailureReason } from '../helpers/getOperationFailureReason';

function gotSurveyResults(surveyResults: {
  name: string;
  questions: Array<Omit<SurveyResultsQuestion, 'dataKeyStates'>>;
}): GotSurveyResultsAction {
  return {
    type: GOT_SURVEY_RESULTS,
    payload: surveyResults,
  };
}

function parseDates(surveyResults: GetSurveyResultsResponse) {
  return {
    ...surveyResults,
    questions: surveyResults.questions.map((question) => ({
      ...question,
      answers: question.answers.map((answer) => {
        const date = parseISO(answer.datetime);
        const weekday = getDay(date);
        return {
          ...answer,
          weekday: weekday === 0 ? 7 : weekday,
          hour: getHours(date),
          dayOfMonth: getDate(date),
          date,
        };
      }),
    })),
  };
}

export function loadSurveyResults(surveyId: string): Thunk<Actions> {
  return (dispatch) => {
    dispatch(startServerRequest());
    serverComm
      .execGetRequest<GetSurveyResultsResponse>(`${SURVEY_RESULTS}/${surveyId}`)
      .then(
        (resp) => {
          if (resp.succeeded === true) {
            dispatch(serverRequestSucceeded());
            const parsedSurvey = parseDates(resp.body);
            dispatch(gotSurveyResults(parsedSurvey));
          } else {
            dispatch(serverRequestFailed(getOperationFailureReason(resp)));
          }
        },
        (e: unknown) => {
          dispatch(serverRequestFailed(getErrorModalMessage(e)));
        },
      )
      .catch((e: unknown) => {
        sendErrorToServer(serverComm, e);
        dispatch(showError(getErrorModalMessage(e)));
      });
  };
}
