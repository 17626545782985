import { connect } from 'react-redux';

import { ThunkDispatch } from 'redux-thunk';
import { createMatchSelector, RouterRootState } from 'connected-react-router';
import { Header } from '../components/Header/Header';
import { execLogout, toggleDrawer } from '../actions';
import { SURVEY_RESULTS_VIEW } from '../constants';
import { ReduxState } from '../types';
import { Actions } from '../actions/reduxActionTypes';

const matchSelector = createMatchSelector<
  RouterRootState,
  { surveyId: string }
>(SURVEY_RESULTS_VIEW);

const mapStateToProps = (state: ReduxState) => {
  const match = matchSelector(state);

  return {
    showMenuIcon: match !== null,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ReduxState, undefined, Actions>,
) => ({
  execLogout() {
    dispatch(execLogout());
  },
  toggleDrawer() {
    dispatch(toggleDrawer(true));
  },
});

export const HeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Header);
