import { FieldArrayRenderProps } from 'formik';
import { Box, Grid, IconButton } from '@material-ui/core';
import { TextField } from '@gts-common/client';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { CustomAnswerTypeAnswer } from '../../../types';

interface InputFieldsProps {
  values: Array<CustomAnswerTypeAnswer>;
  arrayHelpers: FieldArrayRenderProps;
  isPending: boolean;
  namePrefix: string;
}

export const InputFields = ({
  values,
  arrayHelpers,
  isPending,
  namePrefix,
}: InputFieldsProps) => {
  return (
    <>
      {values.map((val, index) => (
        <Grid container key={val.answerTypeAnswerId} alignItems="center">
          <Grid item xs>
            <TextField
              label={`Antwort ${index + 1}`}
              name={`${namePrefix}${index}.label`}
              required={true}
            />
          </Grid>
          <Grid item>
            <Box textAlign="center">
              <IconButton
                color="primary"
                size="small"
                onClick={() => arrayHelpers.remove(index)}
                disabled={!isPending || values.length < 3}
              >
                <RemoveCircleIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      ))}
    </>
  );
};
