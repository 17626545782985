import { Box, Container, Typography } from '@material-ui/core';

export function NonePanel() {
  return (
    <Container maxWidth="sm">
      <Box textAlign="justify" mt="1rem">
        <Typography>
          Es wird keine Nachfrage gestellt, warum der Kunde den Eintrag
          ausgewählt hat.
        </Typography>
        <br />
        <Typography>
          <strong>Hinweis:</strong>
        </Typography>
        <br />
        <Typography gutterBottom={true}>
          Bei einer positiven Bewertung ist es zu empfehlen, Ihren Kunden zu
          animieren, auch z. B. in Google eine positive Bewertung zu
          hinterlassen mit der Option Empfehlungsseite. Empfehlungsseiten können
          Sie nur für Online-Umfragen definieren.
        </Typography>
        <Typography gutterBottom={true}>
          Bei negativen oder neutralen Bewertungen sollten Sie bei Ihrem Kunden
          besser nachfragen, warum Sie sich so entschieden haben, damit Sie die
          Ursachen herausfinden können und diese beseitigen können.
        </Typography>
      </Box>
    </Container>
  );
}
