import {
  Grid,
  Typography,
  Hidden,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Paper,
} from '@material-ui/core';
import { useSwipeable } from 'react-swipeable';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';

import { getColors } from '@gts-ns/ui';

import { AnswerTypeType } from '@gts-ns/utils';
import { SurveyResultsDrawerContainer } from '../../containers/SurveyResultsDrawer';
import { LegendContainer } from '../../containers/Legend';
import { ChartType, ResultPeriod } from '../../constants';
import { AnswerTypeAnswer, SurveyResultsQuestion } from '../../types';
import { Picker } from './helpers/Picker';
import { PrimaryChart } from './Charts/PrimaryChart';
import { CustomPieChart } from './Charts/CustomPieChart';
import { swipeLeft, swipeRight } from './helpers/swipeHelpers';
import { SurveyResultsTabs } from './helpers/SurveyResultTabs';

interface Props {
  selectedQuestion: SurveyResultsQuestion;
  periodType: ResultPeriod;
  primaryChartType: ChartType;
  selectedDate: Date;
  showSecondaryChart: boolean;
  selectedQuestionTab: number;
  firstAnswerType: AnswerTypeType;
  singleValueAnswers: Array<{ label: string }>;
  answersWithQuestion: Array<AnswerTypeAnswer & { answerIndex: number }>;
  execChangeDate: (date: Date) => void;
  execSelectQuestion: (num: number) => void;
  // Contains a day of the week as label and further properties which are the labels of the answers
  // for example 1, 2, 3 etc. for the 1 to 10 type
  answers: Array<{ label: string }>;
}

export const SurveyResults = ({
  periodType,
  primaryChartType,
  selectedQuestion,
  singleValueAnswers,
  answers,
  selectedDate,
  execChangeDate,
  showSecondaryChart,
  selectedQuestionTab,
  answersWithQuestion,
  execSelectQuestion,
  firstAnswerType,
}: Props) => {
  const dataKeys = Object.keys(answers[0]).filter((key) => key !== 'label');
  const colors = getColors(selectedQuestion.answerType, dataKeys.length);
  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => swipeLeft(periodType, execChangeDate, selectedDate),
    onSwipedRight: () => swipeRight(periodType, execChangeDate, selectedDate),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <>
      <SurveyResultsDrawerContainer />
      <Box {...swipeHandlers}>
        <Grid container>
          <Grid item xs={12}>
            <Picker
              periodType={periodType}
              selectedDate={selectedDate}
              execChangeDate={execChangeDate}
            />
          </Grid>
          <Hidden smDown lgUp>
            <Grid item md={1} />
          </Hidden>
          {answersWithQuestion.length > 0 && (
            <Grid item xs={12}>
              <Box mt="1rem" mb="1rem">
                <Paper elevation={2}>
                  <SurveyResultsTabs
                    answerType={firstAnswerType}
                    answersWithQuestion={answersWithQuestion}
                    value={selectedQuestionTab}
                    setValue={execSelectQuestion}
                  />
                </Paper>
              </Box>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography align="center">{selectedQuestion.question}</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={showSecondaryChart ? 8 : 12}
            lg={showSecondaryChart ? 9 : 12}
          >
            <PrimaryChart
              answers={answers}
              dataKeys={dataKeys}
              answerType={selectedQuestion.answerType}
              colors={colors}
              type={primaryChartType}
              dataKeyStates={selectedQuestion.dataKeyStates}
            />
          </Grid>
          {showSecondaryChart && (
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <CustomPieChart answers={singleValueAnswers} colors={colors} />
            </Grid>
          )}
          <Grid item xs={12}>
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">Legende</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <LegendContainer />
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
