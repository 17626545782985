import { serverComm } from '@gts-ns/ui';
import { EditSurveyRequestBody, SURVEY } from '@gts-ns/utils';
import {
  createPartial,
  getErrorModalMessage,
  sendErrorToServer,
  serverRequestFailed,
  serverRequestSucceeded,
  showError,
  startServerRequest,
} from '@gts-common/client';
import {
  Actions,
  SURVEY_UPDATED,
  SurveyUpdatedAction,
  Thunk,
} from '../reduxActionTypes';
import { execReplace } from '../navigation';
import { EditSurvey } from '../../types';
import { HOME_VIEW } from '../../constants';
import { getOperationFailureReason } from '../helpers/getOperationFailureReason';
import { prepareUpdateSurveyForServer } from '../helpers/prepareUpdateSurveyForServer';
import { getConditionalsFromAnswers } from '../helpers/getConditionalsFromAnswers';

function surveyUpdated(
  surveyId: string,
  surveyName: string,
  question: string,
): SurveyUpdatedAction {
  const clientUpdate = { surveyId, name: surveyName, question };
  return {
    type: SURVEY_UPDATED,
    payload: clientUpdate,
  };
}

export function editSurvey(
  surveyId: string,
  surveyUpdate: EditSurvey,
): Thunk<Actions> {
  return (dispatch, getState) => {
    const originalSurvey = getState().surveys.survey;

    if (!originalSurvey) {
      throw new Error('Original survey not found');
    }

    const answers = surveyUpdate.answers;
    const conditionals = getConditionalsFromAnswers(answers);

    const surveyForServer = prepareUpdateSurveyForServer(
      surveyUpdate,
      conditionals,
    );

    const partial: EditSurveyRequestBody = createPartial(
      originalSurvey,
      surveyForServer,
    );

    if (Object.keys(partial).length !== 0) {
      dispatch(startServerRequest());
      serverComm
        .execPatchRequest<Record<string, never>, EditSurveyRequestBody>(
          `${SURVEY}/${surveyId}`,
          partial,
        )
        .then(
          (resp) => {
            if (resp.succeeded) {
              dispatch(
                surveyUpdated(
                  surveyId,
                  surveyForServer.name,
                  surveyForServer.question,
                ),
              );
              dispatch(execReplace(HOME_VIEW));
              dispatch(serverRequestSucceeded('Umfrage aktualisiert.'));
            } else {
              dispatch(serverRequestFailed(getOperationFailureReason(resp)));
            }
          },
          (e: unknown) => {
            dispatch(serverRequestFailed(getErrorModalMessage(e)));
          },
        )
        .catch((e: unknown) => {
          sendErrorToServer(serverComm, e);
          dispatch(showError(getErrorModalMessage(e)));
        });
    } else {
      dispatch(execReplace(HOME_VIEW));
    }
  };
}
