import { Actions, DATA_KEY_STATE_UPDATED, Thunk } from '../reduxActionTypes';

export function updateDataKeyState(
  updates: Record<string, boolean>,
): Thunk<Actions> {
  return (dispatch, getState) => {
    dispatch({
      type: DATA_KEY_STATE_UPDATED,
      payload: { updates, index: getState().surveyResults.selectedQuestionTab },
    });
  };
}
