import {
  BarChart,
  Cell,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { AnswerTypeType } from '@gts-ns/utils';
import { PrimaryTooltip } from './helpers/Tooltips';

interface Props {
  answerType: AnswerTypeType;
  answers: Array<{ label: string }>;
  dataKeys: Array<string>;
  colors: (num: number) => string;
  dataKeyStates: Record<string, boolean>;
}

export const CustomBarChart = ({
  answers,
  dataKeys,
  answerType,
  colors,
  dataKeyStates,
}: Props) => {
  return (
    <ResponsiveContainer aspect={2 / 1}>
      <BarChart
        data={answers}
        barGap={answerType === AnswerTypeType.ONE_TO_TEN ? 1 : 2}
      >
        <CartesianGrid />
        <XAxis dataKey="label" height={34} />
        <YAxis minTickGap={1} allowDecimals={false} />
        <Tooltip content={<PrimaryTooltip colors={colors} />} />
        {dataKeys.map(
          (dataKey, index) =>
            dataKeyStates[dataKey] && (
              <Bar
                dataKey={dataKey}
                isAnimationActive={false}
                key={`${answerType} ${dataKey}`}
              >
                {answers.map((answer) => (
                  <Cell
                    fill={colors(index)}
                    key={`${answerType}${answer.label}`}
                    stroke="black"
                    strokeWidth={
                      answerType === AnswerTypeType.ONE_TO_TEN ? 0.2 : 0.5
                    }
                  />
                ))}
              </Bar>
            ),
        )}
      </BarChart>
    </ResponsiveContainer>
  );
};
