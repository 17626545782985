import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { AddSurveyResponse, LoginResponse, SurveyStatus } from '@gts-ns/utils';
import { CommonActions } from '@gts-common/client';
import { RouterAction } from 'connected-react-router';
import {
  EditSurvey,
  PeriodChange,
  ReduxState,
  SurveyResultsQuestion,
} from '../types';
import { ChartType, ResultView } from '../constants';

export const LOGIN_SUCCEEDED = 'LOGIN_SUCCEEDED';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGIN_CHECK_SUCCEEDED = 'LOGIN_CHECK_SUCCEEDED';
export const LOGIN_CHECK_FAILED = 'LOGIN_CHECK_FAILED';
export const LOGOUT_SUCCEEDED = 'LOGOUT_SUCCEEDED';

export const GOT_SURVEY = 'GOT_SURVEY';
export const GOT_SURVEY_RESULTS = 'GOT_SURVEY_RESULTS';
export const SURVEY_ADDED = 'SURVEY_ADDED';
export const SURVEY_UPDATED = 'SURVEY_UPDATED';
export const SURVEY_STATUS_CHANGED = 'SURVEY_STATUS_CHANGED';

// Question selection in survey results
export const QUESTION_SELECTED = 'QUESTION_SELECTED';

export const PRIMARY_CHART_TYPE_CHANGED = 'PRIMARY_CHART_TYPE_CHANGED';
export const SECONDARY_CHART_TOGGLED = 'SECONDARY_CHART_TOGGLED';
export const DATA_KEY_STATE_UPDATED = 'DATA_KEY_STATE_UPDATED';

export const DRAWER_TOGGLED = 'DRAWER_TOGGLED';

export const CHANGE_SURVEY_RESULTS_VIEW = 'CHANGE_SURVEY_RESULTS_VIEW';
export const CHANGE_SURVEY_RESULTS_PERIOD_FIELDS =
  'CHANGE_SURVEY_RESULTS_PERIOD_FIELDS';

export const EXPAND_SURVEY_SECTION = 'EXPAND_SURVEY_SECTION';

export interface LoginSucceededAction extends Action {
  type: typeof LOGIN_SUCCEEDED;
  payload: LoginResponse;
}

export interface LoginFailedAction extends Action {
  type: typeof LOGIN_FAILED;
}

export interface LoginCheckFailedAction extends Action {
  type: typeof LOGIN_CHECK_FAILED;
}

export interface LoginCheckSucceededAction extends Action {
  type: typeof LOGIN_CHECK_SUCCEEDED;
  payload: LoginResponse;
}

export interface LogoutSucceededAction extends Action {
  type: typeof LOGOUT_SUCCEEDED;
}

export interface GotSurveyAction {
  type: typeof GOT_SURVEY;
  payload: EditSurvey;
}

export interface GotSurveyResultsAction {
  type: typeof GOT_SURVEY_RESULTS;
  payload: {
    name: string;
    questions: Array<Omit<SurveyResultsQuestion, 'dataKeyStates'>>;
  };
}

export interface SurveyAddedAction {
  type: typeof SURVEY_ADDED;
  payload: AddSurveyResponse;
}

export interface SurveyUpdatedAction {
  type: typeof SURVEY_UPDATED;
  payload: {
    surveyId: string;
    name?: string;
    question?: string;
  };
}

export interface SurveyStatusChangedAction {
  type: typeof SURVEY_STATUS_CHANGED;
  payload: {
    surveyId: string;
    status: SurveyStatus;
  };
}

export interface QuestionSelectedAction {
  type: typeof QUESTION_SELECTED;
  payload: number;
}

export interface PrimaryChartTypeChangedAction {
  type: typeof PRIMARY_CHART_TYPE_CHANGED;
  payload: ChartType;
}

export interface SecondaryChartToggledAction {
  type: typeof SECONDARY_CHART_TOGGLED;
  payload: boolean;
}

export interface DataKeyStateUpdatedAction {
  type: typeof DATA_KEY_STATE_UPDATED;
  payload: {
    updates: Record<string, boolean>;
    index: number;
  };
}

export interface DrawerToggledAction {
  type: typeof DRAWER_TOGGLED;
  payload: boolean;
}

export interface ChangeSurveyResultsViewAction {
  type: typeof CHANGE_SURVEY_RESULTS_VIEW;
  payload: ResultView;
}

export interface ChangeSurveyResultsPeriodFieldsAction {
  type: typeof CHANGE_SURVEY_RESULTS_PERIOD_FIELDS;
  payload: PeriodChange;
}

export interface ExpandSurveySectionAction {
  type: typeof EXPAND_SURVEY_SECTION;
  payload: { section: SurveyStatus; expanded: boolean };
}

export type SurveyActions =
  | GotSurveyAction
  | GotSurveyResultsAction
  | SurveyAddedAction
  | SurveyUpdatedAction
  | SurveyStatusChangedAction
  | QuestionSelectedAction;

export type SurveyResultsViewActions =
  | PrimaryChartTypeChangedAction
  | SecondaryChartToggledAction
  | DataKeyStateUpdatedAction
  | DrawerToggledAction
  | ChangeSurveyResultsViewAction
  | ChangeSurveyResultsPeriodFieldsAction;

export type Actions =
  | LoginSucceededAction
  | LoginFailedAction
  | LoginCheckSucceededAction
  | LoginCheckFailedAction
  | LogoutSucceededAction
  | SurveyActions
  | SurveyResultsViewActions
  | ExpandSurveySectionAction
  | RouterAction
  | CommonActions;

export type Thunk<A extends Action> = ThunkAction<void, ReduxState, unknown, A>;
