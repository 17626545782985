import { LoginStatus } from '@gts-common/client';
import { initialState } from '../initialState';
import {
  Actions,
  EXPAND_SURVEY_SECTION,
  LOGIN_CHECK_FAILED,
  LOGIN_CHECK_SUCCEEDED,
  LOGIN_FAILED,
  LOGIN_SUCCEEDED,
  LOGOUT_SUCCEEDED,
} from '../actions/reduxActionTypes';
import { AppState } from '../types';

export function appReducer(
  state = initialState.app,
  action: Actions,
): AppState {
  switch (action.type) {
    // Reset all data
    case LOGOUT_SUCCEEDED:
      return {
        ...initialState.app,
        loginStatus: LoginStatus.LOGGED_OUT,
      };

    case LOGIN_SUCCEEDED:
      return {
        ...state,
        loginStatus: LoginStatus.LOGGED_IN,
      };

    case LOGIN_FAILED:
      return {
        ...state,
        loginStatus: LoginStatus.LOGGED_OUT,
      };

    case LOGIN_CHECK_SUCCEEDED:
      return {
        ...state,
        loginStatus: LoginStatus.LOGGED_IN,
      };

    case LOGIN_CHECK_FAILED:
      return {
        ...state,
        loginStatus: LoginStatus.LOGGED_OUT,
      };

    case EXPAND_SURVEY_SECTION:
      return {
        ...state,
        surveyExpandStates: {
          ...state.surveyExpandStates,
          [action.payload.section]: action.payload.expanded,
        },
      };

    default:
      return state;
  }
}
