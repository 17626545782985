import { SurveyStatus } from '@gts-ns/utils';
import { LoginStatus } from '@gts-common/client';
import { CURRENT_DATE, ChartType, ResultView, ResultPeriod } from './constants';
import { ReduxState } from './types';

export const initialState: Omit<ReduxState, 'router' | 'common'> = {
  app: {
    loginStatus: LoginStatus.PENDING,
    surveyExpandStates: {
      [SurveyStatus.ACTIVE]: true,
      [SurveyStatus.PENDING]: true,
      [SurveyStatus.TERMINATED]: true,
    },
  },
  surveys: {
    surveys: [],
    survey: undefined,
    conditionals: { selectedConditionals: [] },
  },
  surveyResults: {
    drawerOpen: false,
    view: ResultView.WEEK_RESULTS_VIEW,
    period: {
      type: ResultPeriod.WEEK_RESULTS_PERIOD,
      date: CURRENT_DATE,
    },
    primaryChartType: ChartType.BAR_CHART,
    showSecondaryChart: true,
    selectedQuestionTab: 0,
    name: '',
  },
  answerTypes: {
    answerTypes: [],
  },
};
