import {
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/Edit';
import BarChartIcon from '@material-ui/icons/BarChart';
import LinkIcon from '@material-ui/icons/Link';
import { useState, MouseEvent } from 'react';
import { SurveyStatus } from '@gts-ns/utils';
import { QRIcon } from './QRIcon';

interface Props {
  surveyStatus: SurveyStatus;
  widgetLink: string;
  showSurveyEdit: () => void;
  showSurveyResults: () => void;
  showSurveyQR: () => void;
}

export const SurveyCardMenu = ({
  surveyStatus,
  widgetLink,
  showSurveyEdit,
  showSurveyResults,
  showSurveyQR,
}: Props) => {
  const [anchor, setAnchor] = useState<MouseEvent['currentTarget'] | null>(
    null,
  );

  return (
    <>
      <IconButton onClick={(event) => setAnchor(event.currentTarget)}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchor)}
        onClose={() => setAnchor(null)}
        onClick={() => setAnchor(null)}
      >
        <MenuItem
          disabled={surveyStatus !== SurveyStatus.ACTIVE}
          component="a"
          href={widgetLink}
          target="_blank"
        >
          <ListItemIcon>
            <LinkIcon />
          </ListItemIcon>
          <ListItemText>Link</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem
          disabled={surveyStatus !== SurveyStatus.ACTIVE}
          onClick={() => showSurveyQR()}
        >
          <ListItemIcon>
            <QRIcon />
          </ListItemIcon>
          <ListItemText>QR-Code</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem
          disabled={surveyStatus === SurveyStatus.PENDING}
          onClick={() => showSurveyResults()}
        >
          <ListItemIcon>
            <BarChartIcon />
          </ListItemIcon>
          <ListItemText>Statistik</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => showSurveyEdit()}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText>Bearbeiten</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};
