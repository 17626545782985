import {
  PRIMARY_CHART_TYPE_CHANGED,
  PrimaryChartTypeChangedAction,
} from '../reduxActionTypes';
import { ChartType } from '../../constants';

export function changePrimaryChartType(
  primaryChartType: ChartType,
): PrimaryChartTypeChangedAction {
  return {
    type: PRIMARY_CHART_TYPE_CHANGED,
    payload: primaryChartType,
  };
}
