import { Formik } from 'formik';

import { Box } from '@material-ui/core';
import { View, AddButton, SelectOption } from '@gts-common/client';
import { addSurveyValidation } from '../../validationSchemata';
import { ADD_SURVEY_FORM_NAME, HOME_VIEW } from '../../constants';
import { AddSurvey } from '../../types';
import { SurveyForm } from './SurveyForm';

export interface SurveyAddProps {
  execAddSurvey: (newSurvey: AddSurvey) => void;
  survey: AddSurvey;
  customAnswerTypeId: string;
  selectAnswerTypeOptions: Array<SelectOption>;
}

export const SurveyAdd = ({
  execAddSurvey,
  survey,
  customAnswerTypeId,
  selectAnswerTypeOptions,
}: SurveyAddProps) => {
  return (
    <View
      hasBackButton={true}
      navigateBackTo={HOME_VIEW}
      backButtonLabel="Startseite"
      title="Umfrage erstellen"
    >
      <Formik
        onSubmit={(newSurvey) => execAddSurvey(newSurvey)}
        initialValues={survey}
        validationSchema={addSurveyValidation(customAnswerTypeId)}
      >
        <SurveyForm
          selectAnswerTypeOptions={selectAnswerTypeOptions}
          isEdit={false}
          customAnswerTypeId={customAnswerTypeId}
          isPending={true}
          formId={ADD_SURVEY_FORM_NAME}
        />
      </Formik>
      <Box mt="1rem" textAlign="center">
        <AddButton label="Erstellen" submitForForm={ADD_SURVEY_FORM_NAME} />
      </Box>
    </View>
  );
};
