import { initialState } from '../initialState';
import {
  GOT_SURVEY_RESULTS,
  DRAWER_TOGGLED,
  CHANGE_SURVEY_RESULTS_VIEW,
  CHANGE_SURVEY_RESULTS_PERIOD_FIELDS,
  PRIMARY_CHART_TYPE_CHANGED,
  SECONDARY_CHART_TOGGLED,
  DATA_KEY_STATE_UPDATED,
  QUESTION_SELECTED,
  Actions,
} from '../actions/reduxActionTypes';
import { SurveyResultsState } from '../types';

export function surveyResultsReducer(
  state = initialState.surveyResults,
  action: Actions,
): SurveyResultsState {
  switch (action.type) {
    case GOT_SURVEY_RESULTS:
      return {
        ...state,
        ...action.payload,
        selectedQuestionTab: 0,
        questions: action.payload.questions.map((question) => ({
          ...question,
          dataKeyStates: question.labels.reduce(
            (prev, label) => ({ ...prev, [label]: true }),
            {},
          ),
        })),
      };

    case DRAWER_TOGGLED:
      return {
        ...state,
        drawerOpen: action.payload,
      };

    case CHANGE_SURVEY_RESULTS_VIEW:
      return {
        ...state,
        view: action.payload,
      };

    case CHANGE_SURVEY_RESULTS_PERIOD_FIELDS:
      return {
        ...state,
        period: {
          ...state.period,
          ...action.payload,
        },
        view: action.payload.view ?? state.view,
      };

    case PRIMARY_CHART_TYPE_CHANGED:
      return {
        ...state,
        primaryChartType: action.payload,
      };

    case SECONDARY_CHART_TOGGLED:
      return {
        ...state,
        showSecondaryChart: action.payload,
      };

    case DATA_KEY_STATE_UPDATED: {
      const questions = state.questions;

      if (!questions) {
        throw new Error('Questions is not defined');
      }

      return {
        ...state,
        questions: questions.map((question, index) =>
          index === action.payload.index
            ? {
                ...question,
                dataKeyStates: {
                  ...questions[index].dataKeyStates,
                  ...action.payload.updates,
                },
              }
            : question,
        ),
      };
    }

    case QUESTION_SELECTED:
      return {
        ...state,
        selectedQuestionTab: action.payload,
      };
    default:
      return state;
  }
}
