import { Grid, Typography, Box } from '@material-ui/core';
import { AddButton } from '@gts-common/client';
import { FieldArray } from 'formik';
import { EditSurvey } from '../../../types';
import { MoveArrows } from './MoveArrows';
import { InputFields } from './InputFields';

interface CustomAnswerFormsProps {
  name: string;
  isPending: boolean;
}

export const CustomAnswersForm = ({
  isPending,
  name,
}: CustomAnswerFormsProps) => (
  <FieldArray name={name}>
    {(arrayHelpers) => {
      const { value, error } = arrayHelpers.form.getFieldMeta(name);
      const values = value as EditSurvey['answers'];

      let customAnswersError = ' ';
      // Since we are dealing with an array here the formik errors object can be an object
      // containing an array with all the errors of individual fields in the array.
      // The error for the whole array is only a string if only the array itself has an error and none of
      // its fields have errors
      if (typeof error === 'string') {
        customAnswersError = error;
      }

      return (
        <>
          <Grid container spacing={2}>
            <Grid item>
              <Box
                display="flex"
                justifyContent="space-around"
                flexDirection="column"
                height="100%"
              >
                <MoveArrows values={values} arrayHelpers={arrayHelpers} />
              </Box>
            </Grid>
            <Grid item xs>
              <InputFields
                isPending={isPending}
                values={values}
                arrayHelpers={arrayHelpers}
                namePrefix={`${name}.`}
              />
            </Grid>
          </Grid>
          <Typography variant="body2" color="error">
            {customAnswersError}
          </Typography>
          {isPending && values.length < 10 && (
            <Box textAlign="center" mt="1rem">
              <AddButton
                onClick={() =>
                  arrayHelpers.push({
                    label: '',
                    answerTypeAnswerId: `${new Date().getTime()}`,
                  })
                }
                label="Hinzufügen"
              />
            </Box>
          )}
        </>
      );
    }}
  </FieldArray>
);
