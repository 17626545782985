import { SvgIcon } from '@material-ui/core';

export const QRIcon = () => {
  return (
    <SvgIcon>
      <path d="M17.25 3C16.84 3 16.5 3.34 16.5 3.75C16.5 4.05 16.5 6.45 16.5 6.75C16.5 7.16 16.84 7.5 17.25 7.5C17.55 7.5 19.95 7.5 20.25 7.5C20.66 7.5 21 7.16 21 6.75C21 6.45 21 4.05 21 3.75C21 3.34 20.66 3 20.25 3C19.65 3 17.55 3 17.25 3ZM18 6L18 4.5L19.5 4.5L19.5 6L18 6Z" />
      <path d="M14.25 0C13.84 0 13.5 0.34 13.5 0.75C13.5 1.65 13.5 8.85 13.5 9.75C13.5 10.16 13.84 10.5 14.25 10.5C15.15 10.5 22.35 10.5 23.25 10.5C23.66 10.5 24 10.16 24 9.75C24 8.85 24 1.65 24 0.75C24 0.34 23.66 0 23.25 0C21.45 0 15.15 0 14.25 0ZM15 9L15 1.5L22.5 1.5L22.5 9L15 9Z" />
      <path d="M3.75 3C3.34 3 3 3.34 3 3.75C3 4.05 3 6.45 3 6.75C3 7.16 3.34 7.5 3.75 7.5C4.05 7.5 6.45 7.5 6.75 7.5C7.16 7.5 7.5 7.16 7.5 6.75C7.5 6.45 7.5 4.05 7.5 3.75C7.5 3.34 7.16 3 6.75 3C6.15 3 4.05 3 3.75 3ZM4.5 6L4.5 4.5L6 4.5L6 6L4.5 6Z" />
      <path d="M0.75 0C0.34 0 0 0.34 0 0.75C0 1.65 0 8.85 0 9.75C0 10.16 0.34 10.5 0.75 10.5C1.65 10.5 8.85 10.5 9.75 10.5C10.16 10.5 10.5 10.16 10.5 9.75C10.5 8.85 10.5 1.65 10.5 0.75C10.5 0.34 10.16 0 9.75 0C7.95 0 1.65 0 0.75 0ZM1.5 9L1.5 1.5L9 1.5L9 9L1.5 9Z" />
      <path d="M3.75 16.5C3.34 16.5 3 16.84 3 17.25C3 17.55 3 19.95 3 20.25C3 20.66 3.34 21 3.75 21C4.05 21 6.45 21 6.75 21C7.16 21 7.5 20.66 7.5 20.25C7.5 19.95 7.5 17.55 7.5 17.25C7.5 16.84 7.16 16.5 6.75 16.5C6.15 16.5 4.05 16.5 3.75 16.5ZM4.5 19.5L4.5 18L6 18L6 19.5L4.5 19.5Z" />
      <path d="M18 19.5C18 19.05 18 18.8 18 18.75C18 18.34 17.66 18 17.25 18C16.84 18 16.5 18.34 16.5 18.75C16.5 18.8 16.5 19.05 16.5 19.5C16.05 19.5 15.8 19.5 15.75 19.5C15.34 19.5 15 19.84 15 20.25C15 20.55 15 22.95 15 23.25C15 23.66 15.34 24 15.75 24C16.16 24 16.5 23.66 16.5 23.25C16.5 23.1 16.5 22.35 16.5 21L18 21C18 22.35 18 23.1 18 23.25C18 23.66 18.34 24 18.75 24C19.16 24 19.5 23.66 19.5 23.25C19.5 22.95 19.5 20.55 19.5 20.25C19.5 19.84 19.16 19.5 18.75 19.5C18.65 19.5 18.4 19.5 18 19.5Z" />
      <path d="M20.25 13.5C19.84 13.5 19.5 13.84 19.5 14.25C19.5 14.55 19.5 16.95 19.5 17.25C19.5 17.66 19.84 18 20.25 18C20.66 18 21 17.66 21 17.25C21 17.1 21 16.35 21 15C22.35 15 23.1 15 23.25 15C23.66 15 24 14.66 24 14.25C24 13.84 23.66 13.5 23.25 13.5C22.65 13.5 20.55 13.5 20.25 13.5Z" />
      <path d="M14.25 13.5C13.84 13.5 13.5 13.84 13.5 14.25C13.5 14.55 13.5 16.95 13.5 17.25C13.5 17.66 13.84 18 14.25 18C14.66 18 15 17.66 15 17.25C15 17.1 15 16.35 15 15C16.35 15 17.1 15 17.25 15C17.66 15 18 14.66 18 14.25C18 13.84 17.66 13.5 17.25 13.5C16.65 13.5 14.55 13.5 14.25 13.5Z" />
      <path d="M0.75 13.5C0.34 13.5 0 13.84 0 14.25C0 15.15 0 22.35 0 23.25C0 23.66 0.34 24 0.75 24C1.65 24 8.85 24 9.75 24C10.16 24 10.5 23.66 10.5 23.25C10.5 22.35 10.5 15.15 10.5 14.25C10.5 13.84 10.16 13.5 9.75 13.5C7.95 13.5 1.65 13.5 0.75 13.5ZM1.5 22.5L1.5 15L9 15L9 22.5L1.5 22.5Z" />
      <path d="M22.5 18.75C22.5 19.2 22.5 22.8 22.5 23.25C22.5 23.66 22.84 24 23.25 24C23.66 24 24 23.66 24 23.25C24 22.8 24 19.2 24 18.75C24 18.34 23.66 18 23.25 18C22.84 18 22.5 18.34 22.5 18.75Z" />
    </SvgIcon>
  );
};
