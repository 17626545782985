import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { Field, FieldProps } from 'formik';
import { SelectedConditional } from '../../../constants';

interface Props {
  disabled: boolean;
  name: string;
}

export function RadioButtons({ name, disabled }: Props) {
  return (
    <Field name={name}>
      {({ field: { onChange, value } }: FieldProps) => {
        return (
          <FormControl component="fieldset" disabled={disabled}>
            <RadioGroup row name={name} value={value} onChange={onChange}>
              <FormControlLabel
                value={SelectedConditional.NONE}
                control={<Radio />}
                label="Keine Nachfrage"
              />
              <FormControlLabel
                value={SelectedConditional.QUESTION}
                control={<Radio />}
                label="Nachfrage"
              />
              <FormControlLabel
                value={SelectedConditional.LINK}
                control={<Radio />}
                label="Empfehlungsseite"
              />
            </RadioGroup>
          </FormControl>
        );
      }}
    </Field>
  );
}
