import {
  SECONDARY_CHART_TOGGLED,
  SecondaryChartToggledAction,
} from '../reduxActionTypes';

export function toggleSecondaryChart(
  state: boolean,
): SecondaryChartToggledAction {
  return {
    type: SECONDARY_CHART_TOGGLED,
    payload: state,
  };
}
