import { Form } from 'formik';

import { TextField } from '@gts-common/client';
import { FunctionComponent } from 'react';
import { QuestionFields, QuestionFieldsProps } from './QuestionFields';

interface Props extends QuestionFieldsProps {
  formId: string;
  customAnswerTypeId: string;
}

export const SurveyForm: FunctionComponent<Props> = ({
  selectAnswerTypeOptions,
  isEdit,
  isPending,
  formId,
  customAnswerTypeId,
  children,
}) => {
  return (
    <Form id={formId} noValidate={true}>
      <TextField label="Name" name="name" required={true} autoFocus={true} />
      <TextField
        label="Text für Antwortseite"
        name="thankYouText"
        required={true}
      />

      <QuestionFields
        customAnswerTypeId={customAnswerTypeId}
        selectAnswerTypeOptions={selectAnswerTypeOptions}
        isPending={isPending}
        isEdit={isEdit}
        isMainQuestion={true}
      />

      {children}
    </Form>
  );
};
