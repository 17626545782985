import { combineReducers } from 'redux';

import { commonStateReducer } from '@gts-common/client';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { appReducer } from './app';
import { surveysReducer } from './surveys';
import { answerTypesReducer } from './answerTypes';
import { surveyResultsReducer } from './surveyResults';

export const rootReducer = (history: History) =>
  combineReducers({
    app: appReducer,
    surveys: surveysReducer,
    answerTypes: answerTypesReducer,
    surveyResults: surveyResultsReducer,
    common: commonStateReducer,
    router: connectRouter(history),
  });
