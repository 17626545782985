import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { LocaleUtils } from 'react-day-picker/types/LocaleUtils';
import {
  MONTHS,
  WEEKDAYS_LONG,
  WEEKDAYS_SHORT,
  FIRST_DAY,
} from '../../../constants';

function formatDay(d: Date, locale: string) {
  const weekday = WEEKDAYS_LONG[locale][d.getDay()];
  const month = MONTHS[locale][d.getMonth()];

  return `${weekday}, ${d.getDate()} ${month} ${d.getFullYear()}`;
}

function formatMonthTitle(d: Date, locale: string) {
  return `${MONTHS[locale][d.getMonth()]} ${d.getFullYear()}`;
}

function formatWeekdayShort(i: number, locale: string) {
  return WEEKDAYS_SHORT[locale][i];
}

function formatWeekdayLong(i: number, locale: string) {
  return WEEKDAYS_LONG[locale][i];
}

function getFirstDayOfWeek(locale: string) {
  return FIRST_DAY[locale];
}

const localeUtils: LocaleUtils = {
  formatDay,
  formatMonthTitle,
  formatWeekdayShort,
  formatWeekdayLong,
  getFirstDayOfWeek,
} as LocaleUtils;

interface DatepickerProps {
  onChange: (day: Date) => void;
}

const Datepicker = ({ onChange }: DatepickerProps) => {
  return (
    <DayPicker
      toMonth={new Date()}
      onDayClick={onChange}
      onWeekClick={(_weekNumber, days) => onChange(days[0])}
      locale="de"
      localeUtils={localeUtils}
      showWeekNumbers
    />
  );
};

export default Datepicker;
