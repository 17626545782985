import { serverComm } from '@gts-ns/ui';
import { ClientGetSurveyResponse, SURVEY } from '@gts-ns/utils';
import {
  getErrorModalMessage,
  sendErrorToServer,
  serverRequestFailed,
  serverRequestSucceeded,
  showError,
  startServerRequest,
} from '@gts-common/client';
import {
  Actions,
  GOT_SURVEY,
  GotSurveyAction,
  Thunk,
} from '../reduxActionTypes';
import { getOperationFailureReason } from '../helpers/getOperationFailureReason';
import { SelectedConditional } from '../../constants';
import { EditSurvey, MainQuestionAnswerTypeAnswer } from '../../types';

function gotSurvey(survey: EditSurvey): GotSurveyAction {
  return {
    type: GOT_SURVEY,
    payload: survey,
  };
}

export function loadSurvey(surveyId: string): Thunk<Actions> {
  return (dispatch) => {
    dispatch(startServerRequest());
    serverComm
      .execGetRequest<ClientGetSurveyResponse>(`${SURVEY}/${surveyId}`)
      .then(
        (resp) => {
          if (resp.succeeded) {
            dispatch(serverRequestSucceeded());
            const answers: Array<MainQuestionAnswerTypeAnswer> = resp.body.answers.map(
              (answer) => {
                if (answer.conditionalQuestion) {
                  return {
                    ...answer,
                    selectedConditional: SelectedConditional.QUESTION,
                  };
                } else if (answer.conditionalLink) {
                  return {
                    ...answer,
                    selectedConditional: SelectedConditional.LINK,
                  };
                } else {
                  return {
                    ...answer,
                    selectedConditional: SelectedConditional.NONE,
                  };
                }
              },
            );

            const survey: EditSurvey = {
              ...resp.body,
              answers,
            };
            dispatch(gotSurvey(survey));
          } else {
            dispatch(serverRequestFailed(getOperationFailureReason(resp)));
          }
        },
        (e: unknown) => {
          dispatch(serverRequestFailed(getErrorModalMessage(e)));
        },
      )
      .catch((e: unknown) => {
        sendErrorToServer(serverComm, e);
        dispatch(showError(getErrorModalMessage(e)));
      });
  };
}
