import {
  Avatar,
  Badge,
  createStyles,
  Tab,
  Tabs,
  Theme,
  WithStyles,
  withStyles,
  withWidth,
  WithWidth,
} from '@material-ui/core';
import { AnswerTypeType } from '@gts-ns/utils';
import {
  ContactSupport as ContactSupportIcon,
  ThumbUp as ThumbUpIcon,
} from '@material-ui/icons';
import { ReactNode } from 'react';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { EditSurvey } from '../../../types';

import { getAnswerTypeTabProps } from '../../helpers/getAnswerTypeTabProps';
import { SelectedConditional } from '../../../constants';

const styles = (theme: Theme) =>
  createStyles({
    icon: {
      [theme.breakpoints.down('xs')]: {
        fontSize: 40,
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: 40,
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 60,
      },
    },
    stars: {
      [theme.breakpoints.down('xs')]: {
        fontSize: 8,
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: 21,
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 28,
      },
    },
    badge: {
      padding: theme.spacing(0, 2),
      margin: theme.spacing(2),
    },
  });

interface Props extends WithStyles<typeof styles>, WithWidth {
  answerTypeType: AnswerTypeType;
  answers: EditSurvey['answers'];
  value: number | boolean;
  setValue: (value: number) => void;
}

interface BadgeProps extends WithStyles<typeof styles> {
  children: ReactNode;
  selectedConditional: SelectedConditional;
}

const CustomBadge = ({
  selectedConditional,
  children,
  classes,
}: BadgeProps) => {
  const Icon =
    selectedConditional === SelectedConditional.LINK
      ? ThumbUpIcon
      : ContactSupportIcon;
  return (
    <Badge
      color="secondary"
      badgeContent={<Icon fontSize="small" style={{ margin: '0.2rem' }} />}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      className={classes.badge}
      invisible={selectedConditional === SelectedConditional.NONE}
    >
      {children}
    </Badge>
  );
};

// Note that this is only relevant for non-custom answers
function getTabsVariant(answerTypeType: AnswerTypeType, width: Breakpoint) {
  if (answerTypeType === AnswerTypeType.ONE_TO_TEN) {
    return 'scrollable';
  } else if (answerTypeType === AnswerTypeType.ONE_TO_SIX) {
    if (width === 'lg' || width === 'xl') {
      return 'fullWidth';
    } else {
      return 'scrollable';
    }
  } else if (answerTypeType === AnswerTypeType.STARS) {
    if (width === 'lg' || width === 'xl') {
      return 'fullWidth';
    } else {
      return 'scrollable';
    }
  } else if (answerTypeType === AnswerTypeType.SMILEYS) {
    if (width === 'sm' || width === 'md' || width === 'lg' || width === 'xl') {
      return 'fullWidth';
    } else {
      return 'scrollable';
    }
  } else {
    return 'fullWidth';
  }
}

function UnstyledSurveyAnswersTabs({
  answerTypeType,
  answers,
  value,
  setValue,
  classes,
  width,
}: Props) {
  const tabsVariant = getTabsVariant(answerTypeType, width);
  return (
    <Tabs
      TabIndicatorProps={{
        style: {
          height: '0.35rem',
        },
      }}
      variant={tabsVariant}
      value={value}
      onChange={(_event, newValue) => setValue(newValue)}
    >
      {answers.map((answer, answerIndex) => {
        const { icon, label, color } = getAnswerTypeTabProps(
          answerTypeType,
          answerIndex,
          answer.label,
          classes,
        );

        return (
          <Tab
            key={answer.label}
            icon={
              <CustomBadge
                selectedConditional={answer.selectedConditional}
                classes={classes}
              >
                {icon ?? (
                  <Avatar style={{ backgroundColor: color }}>{label}</Avatar>
                )}
              </CustomBadge>
            }
          />
        );
      })}
    </Tabs>
  );
}

export const SurveyAnswersTabs = withWidth()(
  withStyles(styles)(UnstyledSurveyAnswersTabs),
);
