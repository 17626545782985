import {
  LOGIN_PATH,
  LoginRequestBody,
  LoginResponse,
  LOGOUT_PATH,
  okFailedResponseBodies,
} from '@gts-ns/utils';

import { serverComm } from '@gts-ns/ui';

import {
  getErrorModalMessage,
  serverRequestFailed,
  serverRequestSucceeded,
  startServerRequest,
  getQueryParameterByName,
  sendErrorToServer,
  showError,
} from '@gts-common/client';
import {
  getUrl,
  ServiceCode,
  UrlType,
  ServerResponseError,
  ServerResponseErrorType,
} from '@gts-common/client-server';
import {
  LOGIN_CHECK_FAILED,
  LOGIN_CHECK_SUCCEEDED,
  LOGIN_FAILED,
  LOGIN_SUCCEEDED,
  Thunk,
  LoginCheckSucceededAction,
  LoginCheckFailedAction,
  LoginSucceededAction,
  LoginFailedAction,
  Actions,
  LOGOUT_SUCCEEDED,
  LogoutSucceededAction,
} from './reduxActionTypes';
import { getOperationFailureReason } from './helpers/getOperationFailureReason';

function loginCheckSucceeded(
  surveys: LoginResponse,
): LoginCheckSucceededAction {
  return {
    type: LOGIN_CHECK_SUCCEEDED,
    payload: surveys,
  };
}

function loginCheckFailed(): LoginCheckFailedAction {
  return {
    type: LOGIN_CHECK_FAILED,
  };
}

function loginSucceeded(surveys: LoginResponse): LoginSucceededAction {
  return {
    type: LOGIN_SUCCEEDED,
    payload: surveys,
  };
}

function loginFailed(): LoginFailedAction {
  return {
    type: LOGIN_FAILED,
  };
}

export function logoutSucceeded(): LogoutSucceededAction {
  return {
    type: LOGOUT_SUCCEEDED,
  };
}

export function checkLoggedIn(): Thunk<Actions> {
  return (dispatch) => {
    dispatch(startServerRequest());

    serverComm
      .execGetRequest<LoginResponse>(LOGIN_PATH)
      .then(
        (resp) => {
          if (resp.succeeded) {
            dispatch(loginCheckSucceeded(resp.body));
            dispatch(serverRequestSucceeded());
          } else {
            window.location.href = `${getUrl(
              ServiceCode.SERVICE_MANAGER,
              UrlType.APP,
            )}/login`;
          }
        },
        (e: unknown) => {
          if (e instanceof ServerResponseError) {
            if (e.type === ServerResponseErrorType.UNAUTHORIZED) {
              window.location.href = `${getUrl(
                ServiceCode.SERVICE_MANAGER,
                UrlType.APP,
              )}/login`;
              return;
            }
          }
          dispatch(serverRequestFailed(getErrorModalMessage(e)));
          dispatch(loginCheckFailed());
        },
      )
      .catch((e: unknown) => {
        sendErrorToServer(serverComm, e);
        dispatch(showError(getErrorModalMessage(e)));
      });
  };
}

export function execLogin(ticket: string): Thunk<Actions> {
  return (dispatch) => {
    const dataToSend: LoginRequestBody = {
      ticket,
    };

    dispatch(startServerRequest());

    serverComm
      .execPostRequest<LoginResponse, LoginRequestBody>(LOGIN_PATH, dataToSend)
      .then(
        (resp) => {
          if (resp.succeeded) {
            dispatch(loginSucceeded(resp.body));
            dispatch(serverRequestSucceeded());
            // clear ticket from url
            window.history.replaceState({}, document.title, '/');
          } else {
            dispatch(serverRequestFailed(getOperationFailureReason(resp)));
            dispatch(loginFailed());
          }
        },
        (e: unknown) => {
          // If the validation fails (ticket has the from format) tell the user to get a new one
          if (e instanceof ServerResponseError) {
            if (e.type === ServerResponseErrorType.BAD_REQUEST) {
              dispatch(
                serverRequestFailed(
                  getOperationFailureReason({
                    succeeded: false,
                    body: okFailedResponseBodies.LOGIN_TICKET_EXPIRED,
                  }),
                ),
              );
              dispatch(loginFailed());
              return;
            }
          }

          dispatch(serverRequestFailed(getErrorModalMessage(e)));
          dispatch(loginFailed());
        },
      )
      .catch((e: unknown) => {
        sendErrorToServer(serverComm, e);
        dispatch(showError(getErrorModalMessage(e)));
      });
  };
}

export function execResolveLogin(): Thunk<Actions> {
  const ticket = getQueryParameterByName('ticket');
  return (dispatch) => {
    dispatch(ticket ? execLogin(ticket) : checkLoggedIn());
  };
}

export function execLogout(): Thunk<Actions> {
  return (dispatch) => {
    dispatch(startServerRequest());

    serverComm
      .execPostRequest(LOGOUT_PATH)
      .then(
        (resp) => {
          if (resp.succeeded) {
            dispatch(logoutSucceeded());
            window.location.href = `${getUrl(
              ServiceCode.SERVICE_MANAGER,
              UrlType.APP,
            )}/login`;
          } else {
            dispatch(serverRequestFailed(getOperationFailureReason(resp)));
          }
        },
        (e: unknown) => {
          dispatch(serverRequestFailed(getErrorModalMessage(e)));
        },
      )
      .catch((e: unknown) => {
        sendErrorToServer(serverComm, e);
        dispatch(showError(getErrorModalMessage(e)));
      });
  };
}
