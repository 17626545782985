import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnswerTypeType } from '@gts-ns/utils';
import { addSurvey } from '../actions';

import { SurveyAdd } from '../components/Surveys/SurveyAdd';
import { AddSurvey, ReduxState } from '../types';
import { Actions } from '../actions/reduxActionTypes';
import { defaultCustomAnswers } from '../components/helpers/getDefaultConditionalQuestion';
import { prepareAnswerTypeSelectOptions } from './helpers/prepareAnswerTypeSelectOptions';

function getDefaultSurvey(answerTypeId: string): AddSurvey {
  return {
    name: '',
    question: '',
    description: '',
    answerTypeId,
    thankYouText: 'Vielen Dank für Ihr Feedback!',
    answers: [...defaultCustomAnswers],
    isCustom: false,
  };
}

const mapStateToProps = (state: ReduxState) => {
  const {
    defaultAnswerTypeId,
    customAnswerTypeId,
  } = state.answerTypes.answerTypes.reduce(
    (prev, answerType) => {
      if (answerType.type === AnswerTypeType.SMILEYS) {
        return {
          ...prev,
          defaultAnswerTypeId: answerType.answerTypeId,
        };
      } else if (answerType.type === AnswerTypeType.CUSTOM) {
        return {
          ...prev,
          customAnswerTypeId: answerType.answerTypeId,
        };
      } else {
        return prev;
      }
    },
    { defaultAnswerTypeId: '', customAnswerTypeId: '' },
  );

  return {
    selectAnswerTypeOptions: prepareAnswerTypeSelectOptions(
      state.answerTypes.answerTypes,
    ),
    customAnswerTypeId,
    survey: getDefaultSurvey(defaultAnswerTypeId),
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ReduxState, undefined, Actions>,
) => ({
  execAddSurvey(survey: AddSurvey) {
    dispatch(addSurvey(survey));
  },
});

export const SurveyAddContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SurveyAdd);
