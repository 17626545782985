import { Card, CardActions, CardContent, CardHeader } from '@material-ui/core';
import { ListSurvey, SurveyStatus } from '@gts-ns/utils';
import { getWidgetUrl } from '@gts-ns/ui';
import { Button } from '@gts-common/client';
import { SurveyCardMenu } from './SurveyCardMenu';

interface Props {
  survey: ListSurvey;
  onChangeStatus: () => void;
  showSurveyEdit: () => void;
  showSurveyResults: () => void;
  showSurveyQR: () => void;
}

function getStatusButtonLabel(surveyStatus: SurveyStatus) {
  if (surveyStatus === SurveyStatus.PENDING) {
    return 'Aktivieren';
  } else if (surveyStatus === SurveyStatus.ACTIVE) {
    return 'Beenden';
  } else {
    return 'Reaktivieren';
  }
}

export const SurveyCard = ({
  survey,
  onChangeStatus,
  showSurveyEdit,
  showSurveyResults,
  showSurveyQR,
}: Props) => {
  return (
    <Card variant="outlined">
      <CardHeader
        action={
          <SurveyCardMenu
            surveyStatus={survey.status}
            widgetLink={getWidgetUrl(survey.surveyId)}
            showSurveyEdit={showSurveyEdit}
            showSurveyResults={showSurveyResults}
            showSurveyQR={showSurveyQR}
          />
        }
        title={survey.name}
      />
      <CardContent>Frage: {survey.question}</CardContent>
      <CardActions>
        <Button
          label={getStatusButtonLabel(survey.status)}
          onClick={onChangeStatus}
        />
      </CardActions>
    </Card>
  );
};
