import { connect } from 'react-redux';

import { endOfMonth } from 'date-fns';

import { ThunkDispatch } from 'redux-thunk';
import { createMatchSelector, RouterRootState } from 'connected-react-router';
import { AnswerTypeType } from '@gts-ns/utils';
import { execChangeDate, loadSurveyResults } from '../actions';

import { ResultPeriod, SURVEY_RESULTS_VIEW } from '../constants';
import { selectQuestion } from '../actions/surveyResults/selectQuestion';
import { ReduxState } from '../types';

import { Actions } from '../actions/reduxActionTypes';
import { SurveyResultsView } from '../components/SurveyResults/SurveyResultsView';
import { formatAnswersByView } from './helpers/formatAnswersByView';
import { byPeriod } from './helpers/filter';

const matchSelector = createMatchSelector<
  RouterRootState,
  { surveyId: string }
>(SURVEY_RESULTS_VIEW);

const mapStateToProps = (state: ReduxState) => {
  const match = matchSelector(state);
  const surveyId = match ? match.params.surveyId : undefined;
  const { surveyResults } = state;
  const {
    view,
    period,
    primaryChartType,
    showSecondaryChart,
    selectedQuestionTab,
    questions,
  } = surveyResults;

  if (questions) {
    const selectedQuestion = questions[selectedQuestionTab];
    const firstAnswers = questions[0].answerTypeAnswers;
    const answers =
      period.type !== ResultPeriod.NO_PERIOD
        ? selectedQuestion.answers.filter(byPeriod(period))
        : selectedQuestion.answers;

    return {
      surveyId,
      selectedQuestion,
      name: surveyResults.name,
      firstAnswerType: questions[0].answerType,
      answersWithQuestion: firstAnswers
        .map((answer, answerIndex) => ({ ...answer, answerIndex }))
        .filter((answer) =>
          questions.find(
            (question) =>
              question.answerTypeAnswerId === answer.answerTypeAnswerId,
          ),
        ),
      periodType: period.type,
      answers: formatAnswersByView(
        view,
        answers,
        selectedQuestion.labels,
        endOfMonth(period.date).getDate(),
      ),
      singleValueAnswers: formatAnswersByView(
        undefined,
        answers,
        selectedQuestion.labels,
      ),
      selectedDate: period.date,
      primaryChartType,
      showSecondaryChart: showSecondaryChart && answers.length > 0,
      selectedQuestionTab,
    };
  } else {
    return {
      surveyId,
      selectedQuestion: undefined,
      name: surveyResults.name,
      firstAnswerType: AnswerTypeType.CUSTOM,
      answersWithQuestion: [],
      answers: [],
      selectedDate: period.date,
      periodType: period.type,
      primaryChartType,
      selectedQuestionTab,
      showSecondaryChart: false,
      singleValueAnswers: [],
    };
  }
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ReduxState, undefined, Actions>,
) => ({
  loadSurveyResults(surveyId: string) {
    dispatch(loadSurveyResults(surveyId));
  },
  execChangeDate(newDate: Date) {
    dispatch(execChangeDate(newDate));
  },
  execSelectQuestion(index: number) {
    dispatch(selectQuestion(index));
  },
});

export const SurveyResultsViewContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SurveyResultsView);
