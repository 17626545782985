import { useEffect } from 'react';
import { View, PleaseWaitText } from '@gts-common/client';
import { AnswerTypeType } from '@gts-ns/utils';
import { ChartType, HOME_VIEW, ResultPeriod } from '../../constants';
import { AnswerTypeAnswer, SurveyResultsQuestion } from '../../types';
import { SurveyResults } from './SurveyResults';

interface Props {
  surveyId: string | undefined;
  selectedQuestion: SurveyResultsQuestion | undefined;
  periodType: ResultPeriod;
  primaryChartType: ChartType;
  selectedDate: Date;
  showSecondaryChart: boolean;
  selectedQuestionTab: number;
  firstAnswerType: AnswerTypeType;
  singleValueAnswers: Array<{ label: string }>;
  answersWithQuestion: Array<AnswerTypeAnswer & { answerIndex: number }>;
  name: string;
  execChangeDate: (date: Date) => void;
  execSelectQuestion: (num: number) => void;
  loadSurveyResults: (surveyId: string) => void;
  answers: Array<{ label: string }>;
}

export const SurveyResultsView = ({
  surveyId,
  periodType,
  primaryChartType,
  name,
  selectedQuestion,
  singleValueAnswers,
  selectedDate,
  execChangeDate,
  showSecondaryChart,
  selectedQuestionTab,
  answersWithQuestion,
  execSelectQuestion,
  firstAnswerType,
  loadSurveyResults,
  answers,
}: Props) => {
  useEffect(() => {
    if (surveyId) {
      loadSurveyResults(surveyId);
    }
  }, [surveyId, loadSurveyResults]);

  if (selectedQuestion) {
    return (
      <View
        hasBackButton={true}
        navigateBackTo={HOME_VIEW}
        backButtonLabel="Startseite"
        title={name}
      >
        <SurveyResults
          periodType={periodType}
          primaryChartType={primaryChartType}
          selectedQuestion={selectedQuestion}
          singleValueAnswers={singleValueAnswers}
          selectedDate={selectedDate}
          execChangeDate={execChangeDate}
          showSecondaryChart={showSecondaryChart}
          selectedQuestionTab={selectedQuestionTab}
          answersWithQuestion={answersWithQuestion}
          execSelectQuestion={execSelectQuestion}
          firstAnswerType={firstAnswerType}
          answers={answers}
        />
      </View>
    );
  } else {
    return (
      <View
        hasBackButton={true}
        navigateBackTo={HOME_VIEW}
        backButtonLabel="Startseite"
        title="..."
      >
        <PleaseWaitText />
      </View>
    );
  }
};
