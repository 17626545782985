import { EditConditionals, EditSurvey } from '../../types';
import { prepareQuestionForServer } from './prepareQuestionForServer';

export function prepareUpdateSurveyForServer(
  surveyUpdate: EditSurvey,
  conditionals: EditConditionals,
) {
  const trimmedQuestionData = prepareQuestionForServer(
    surveyUpdate,
    surveyUpdate.isCustom,
  );

  const trimmedSurveyData = {
    name: surveyUpdate.name.trim(),
    thankYouText: surveyUpdate.thankYouText.trim(),
  };

  if (surveyUpdate.isCustom) {
    return {
      ...surveyUpdate,
      ...trimmedQuestionData,
      ...trimmedSurveyData,
    };
  } else {
    const questions = conditionals.questions.map(
      ({ question, answerTypeAnswerId }) => ({
        ...prepareQuestionForServer(question, question.isCustom),
        questionId: question.questionId,
        answerTypeAnswerId,
      }),
    );
    const links = conditionals.links.map(({ link, answerTypeAnswerId }) => ({
      ...link,
      url: link.url.trim(),
      headline: link.headline.trim(),
      description: link.description.trim(),
      urlDescription: link.urlDescription.trim(),
      answerTypeAnswerId,
    }));

    return {
      ...surveyUpdate,
      ...trimmedQuestionData,
      ...trimmedSurveyData,
      questions,
      links,
    };
  }
}
