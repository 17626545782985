import { serverComm } from '@gts-ns/ui';
import { SURVEY, SurveyStatus, EditSurveyRequestBody } from '@gts-ns/utils';
import {
  execOpenMessage,
  ExtraModalMessage,
  getErrorModalMessage,
  MessageType,
  ModalType,
  sendErrorToServer,
  serverRequestFailed,
  serverRequestSucceeded,
  showError,
  startServerRequest,
} from '@gts-common/client';
import {
  Actions,
  SURVEY_STATUS_CHANGED,
  SurveyStatusChangedAction,
  Thunk,
} from '../reduxActionTypes';
import { getOperationFailureReason } from '../helpers/getOperationFailureReason';

function statusChanged(
  surveyId: string,
  status: SurveyStatus,
): SurveyStatusChangedAction {
  return {
    type: SURVEY_STATUS_CHANGED,
    payload: { surveyId, status },
  };
}

export function setSurveyStatus(
  surveyId: string,
  status: SurveyStatus,
): Thunk<Actions> {
  return (dispatch) => {
    const surveyUpdate: EditSurveyRequestBody = { status };
    dispatch(startServerRequest());

    serverComm
      .execPatchRequest<Record<string, never>, EditSurveyRequestBody>(
        `${SURVEY}/${surveyId}`,
        surveyUpdate,
      )
      .then(
        (resp) => {
          if (resp.succeeded === true) {
            dispatch(statusChanged(surveyId, status));
            dispatch(serverRequestSucceeded('Umfrage aktualisiert.'));
          } else {
            dispatch(serverRequestFailed(getOperationFailureReason(resp)));
          }
        },
        (e: unknown) => {
          dispatch(serverRequestFailed(getErrorModalMessage(e)));
        },
      )
      .catch((e: unknown) => {
        sendErrorToServer(serverComm, e);
        dispatch(showError(getErrorModalMessage(e)));
      });
  };
}

export function tryToChangeSurveyStatus(
  surveyId: string,
  currentStatus: SurveyStatus,
): Thunk<Actions> {
  return (dispatch) => {
    const newStatus =
      currentStatus === SurveyStatus.ACTIVE
        ? SurveyStatus.TERMINATED
        : SurveyStatus.ACTIVE;
    let modal: ExtraModalMessage;

    if (newStatus === SurveyStatus.ACTIVE) {
      modal = {
        type: MessageType.MODAL,
        modalType: ModalType.DECISION_MODAL,
        title: 'Umfrage aktivieren?',
        body:
          'Sobald Sie diese Umfrage einmal aktiviert haben, können Sie bestimmte Teile der Umfrage nicht mehr bearbeiten. Trotzdem aktivieren?',
        extraProps: {
          confirmAction: setSurveyStatus(surveyId, newStatus),
        },
      };
    } else {
      modal = {
        type: MessageType.MODAL,
        modalType: ModalType.DECISION_MODAL,
        title: 'Umfrage beenden?',
        body:
          'Wenn Sie diese Umfrage beenden, können keine weiteren Antworten mehr gespeichert werden. Trotzdem beenden?',
        extraProps: {
          confirmAction: setSurveyStatus(surveyId, newStatus),
        },
      };
    }

    dispatch(execOpenMessage(modal));
  };
}
