import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { SurveyStatus } from '@gts-ns/utils';
import { push } from 'connected-react-router';
import { tryToChangeSurveyStatus } from '../actions';

import { ReduxState } from '../types';
import { Actions } from '../actions/reduxActionTypes';
import {
  SURVEY_ADD_VIEW,
  SURVEY_EDIT_PATH,
  SURVEY_QR_PATH,
  SURVEY_RESULTS_PATH,
} from '../constants';
import { SurveysListProps } from '../components/Home/SurveysList';
import { expandSurveySection } from '../actions/surveys/expand';
import { Home } from '../components/Home/Home';

const mapStateToProps = (state: ReduxState) => {
  const surveys = state.surveys.surveys.reduce<SurveysListProps['surveys']>(
    (prev, survey) => {
      switch (survey.status) {
        case SurveyStatus.ACTIVE:
          return {
            ...prev,
            [SurveyStatus.ACTIVE]: [...prev[SurveyStatus.ACTIVE], survey],
          };
        case SurveyStatus.PENDING:
          return {
            ...prev,
            [SurveyStatus.PENDING]: [...prev[SurveyStatus.PENDING], survey],
          };
        case SurveyStatus.TERMINATED:
          return {
            ...prev,
            [SurveyStatus.TERMINATED]: [
              ...prev[SurveyStatus.TERMINATED],
              survey,
            ],
          };
      }
    },
    {
      [SurveyStatus.ACTIVE]: [],
      [SurveyStatus.PENDING]: [],
      [SurveyStatus.TERMINATED]: [],
    },
  );

  return {
    surveys,
    surveyExpandStates: state.app.surveyExpandStates,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ReduxState, undefined, Actions>,
) => ({
  showSurveyAdd() {
    dispatch(push(SURVEY_ADD_VIEW));
  },
  showSurveyEdit(surveyId: string) {
    dispatch(push(`${SURVEY_EDIT_PATH}/${surveyId}`));
  },
  showSurveyResults(surveyId: string) {
    dispatch(push(`${SURVEY_RESULTS_PATH}/${surveyId}`));
  },
  showSurveyQr(surveyId: string) {
    dispatch(push(`${SURVEY_QR_PATH}/${surveyId}`));
  },
  execChangeStatus(surveyId: string, currentStatus: SurveyStatus) {
    dispatch(tryToChangeSurveyStatus(surveyId, currentStatus));
  },
  expandSurveySection(section: SurveyStatus, expanded: boolean) {
    dispatch(expandSurveySection(section, expanded));
  },
});

export const HomeContainer = connect(mapStateToProps, mapDispatchToProps)(Home);
