import QRCode from 'qrcode.react';
import { getWidgetUrl } from '@gts-ns/ui';
import { Box, Button } from '@material-ui/core';
import { NotFoundText, View } from '@gts-common/client';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { ListSurvey } from '@gts-ns/utils';
import { HOME_VIEW } from '../../constants';

const downloadQR = (id: string, name: string) => {
  const canvas = document.getElementById(id) as HTMLCanvasElement;

  const pngUrl = canvas
    .toDataURL('image/png')
    .replace('image/png', 'image/octet-stream');
  const downloadLink = document.createElement('a');
  downloadLink.href = pngUrl;
  downloadLink.download = `QR_${name.split(' ').join('_')}.png`;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

interface Props {
  survey: ListSurvey | undefined;
}

export const SurveyQR = ({ survey }: Props) => {
  if (!survey) {
    return (
      <View
        title="QR-Code"
        navigateBackTo={HOME_VIEW}
        hasBackButton={true}
        backButtonLabel="Startseite"
      >
        <NotFoundText>
          Die Umfrage existiert nicht. Bitte überprüfen Sie den Link und
          versuchen Sie es erneut.
        </NotFoundText>
      </View>
    );
  }

  return (
    <View
      title={`QR-Code für "${survey.name}"`}
      navigateBackTo={HOME_VIEW}
      hasBackButton={true}
      backButtonLabel="Startseite"
    >
      <Box textAlign="center">
        <QRCode
          id={survey.surveyId}
          value={getWidgetUrl(survey.surveyId)}
          size={290}
          level={'H'}
          includeMargin={true}
        />
        <br />
        <Button
          color="primary"
          variant="contained"
          onClick={() => downloadQR(survey.surveyId, survey.name)}
          startIcon={<CloudDownloadIcon />}
        >
          Download QR
        </Button>
      </Box>
    </View>
  );
};
