import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { getWidgetUrl } from '@gts-ns/ui';

import {
  createMatchSelector,
  push,
  RouterRootState,
} from 'connected-react-router';
import { AnswerTypeType } from '@gts-ns/utils';
import { editSurvey, loadSurvey } from '../actions';

import { EditSurvey, ReduxState } from '../types';
import { Actions } from '../actions/reduxActionTypes';
import {
  SelectedConditional,
  SURVEY_EDIT_VIEW,
  SURVEY_RESULTS_PATH,
} from '../constants';
import { SurveyEditView } from '../components/Surveys/SurveyEditView';
import { getDefaultConditionalLink } from '../components/helpers/getDefaultConditionalLink';
import {
  defaultCustomAnswers,
  getDefaultConditionalQuestion,
} from '../components/helpers/getDefaultConditionalQuestion';
import { prepareAnswerTypeSelectOptions } from './helpers/prepareAnswerTypeSelectOptions';

const matchSelector = createMatchSelector<
  RouterRootState,
  { surveyId: string }
>(SURVEY_EDIT_VIEW);

// We need to add dummy answers to all non-custom conditional questions so that the user can select the custom answer type for a conditional question that currently is not custom
function addDummyAnswersToConditionalQuestions(
  survey: EditSurvey,
  customAnswerTypeId: string,
) {
  const answersWithDummyCustomAnswers = survey.answers.map((answer) => {
    if (answer.conditionalQuestion && !answer.conditionalQuestion.isCustom) {
      return {
        ...answer,
        conditionalQuestion: {
          ...answer.conditionalQuestion,
          answers: [...defaultCustomAnswers],
        },
      };
    } else {
      return answer;
    }
  });

  const answersWithDummies = answersWithDummyCustomAnswers.map((answer) => {
    if (answer.selectedConditional === SelectedConditional.LINK) {
      return {
        ...answer,
        conditionalQuestion: getDefaultConditionalQuestion(customAnswerTypeId),
      };
    } else if (answer.selectedConditional === SelectedConditional.QUESTION) {
      return {
        ...answer,
        conditionalLink: getDefaultConditionalLink(),
      };
    } else {
      return {
        ...answer,
        conditionalLink: getDefaultConditionalLink(),
        conditionalQuestion: getDefaultConditionalQuestion(customAnswerTypeId),
      };
    }
  });

  return {
    ...survey,
    answers: answersWithDummies,
  };
}

const mapStateToProps = (state: ReduxState) => {
  const match = matchSelector(state);
  const surveyId = match ? match.params.surveyId : undefined;
  const answerTypes = state.answerTypes.answerTypes;
  const survey = state.surveys.survey;

  const customAnswerType = answerTypes.find((type) => type.type === 'CUSTOM');

  if (!customAnswerType) {
    throw new Error('customAnswerType is undefined');
  }

  const customAnswerTypeId = customAnswerType.answerTypeId;

  if (survey) {
    const selectAnswerTypeOptions = prepareAnswerTypeSelectOptions(answerTypes);
    const widgetUrl = getWidgetUrl(survey.surveyId);
    const foundAnswerType = answerTypes.find(
      (answerType) => answerType.answerTypeId === survey.answerTypeId,
    );

    if (!foundAnswerType) {
      throw new Error('Answer type not found');
    }

    return {
      surveyId,
      survey: addDummyAnswersToConditionalQuestions(survey, customAnswerTypeId),
      widgetUrl,
      selectAnswerTypeOptions: selectAnswerTypeOptions,
      answerTypeType: foundAnswerType.type,
      customAnswerTypeId,
    };
  } else {
    return {
      surveyId,
      survey: undefined,
      widgetUrl: '',
      selectAnswerTypeOptions: [],
      answerTypeType: AnswerTypeType.CUSTOM,
      customAnswerTypeId,
    };
  }
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ReduxState, undefined, Actions>,
) => ({
  execEditSurvey(surveyId: string, surveyUpdate: EditSurvey) {
    dispatch(editSurvey(surveyId, surveyUpdate));
  },
  loadSurvey(surveyId: string) {
    dispatch(loadSurvey(surveyId));
  },
  showSurveyResults(surveyId: string) {
    dispatch(push(`${SURVEY_RESULTS_PATH}/${surveyId}`));
  },
});

export const SurveyEditViewContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SurveyEditView);
