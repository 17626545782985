import { SurveyStatus } from '@gts-ns/utils';
import {
  EXPAND_SURVEY_SECTION,
  ExpandSurveySectionAction,
} from '../reduxActionTypes';

export function expandSurveySection(
  section: SurveyStatus,
  expanded: boolean,
): ExpandSurveySectionAction {
  return {
    type: EXPAND_SURVEY_SECTION,
    payload: { section, expanded },
  };
}
