import { ListSurvey, SurveyStatus } from '@gts-ns/utils';
import { AddButton, View } from '@gts-common/client';
import { Box } from '@material-ui/core';
import { AppState } from '../../types';
import { SurveysList } from './SurveysList';

export interface Props {
  surveys: {
    [SurveyStatus.ACTIVE]: Array<ListSurvey>;
    [SurveyStatus.PENDING]: Array<ListSurvey>;
    [SurveyStatus.TERMINATED]: Array<ListSurvey>;
  };
  surveyExpandStates: AppState['surveyExpandStates'];
  showSurveyAdd: () => void;
  showSurveyEdit: (surveyId: string) => void;
  showSurveyResults: (surveyId: string) => void;
  execChangeStatus: (surveyId: string, status: SurveyStatus) => void;
  expandSurveySection: (section: SurveyStatus, expanded: boolean) => void;
  showSurveyQr: (surveyId: string) => void;
}

export const Home = ({
  surveys,
  execChangeStatus,
  showSurveyAdd,
  showSurveyEdit,
  showSurveyResults,
  surveyExpandStates,
  expandSurveySection,
  showSurveyQr,
}: Props) => {
  return (
    <View hasBackButton={false} title="">
      <SurveysList
        surveys={surveys}
        showSurveyEdit={showSurveyEdit}
        showSurveyResults={showSurveyResults}
        execChangeStatus={execChangeStatus}
        surveyExpandStates={surveyExpandStates}
        expandSurveySection={expandSurveySection}
        showSurveyQr={showSurveyQr}
      />

      <Box textAlign="center" mt="1rem">
        <AddButton label="Neue Umfrage" onClick={showSurveyAdd} />
      </Box>
    </View>
  );
};
