import {
  TextField,
  TextareaField,
  SelectField,
  SelectOption,
} from '@gts-common/client';
import { useFormikContext } from 'formik';
import { Box, Paper, Typography } from '@material-ui/core';
import { getIsCustomAnswerType } from '../helpers/getIsCustomAnswerType';
import { Question } from '../../types';
import { CustomAnswersForm } from './CustomAnswers/CustomAnswersForm';

export interface QuestionFieldsProps {
  selectAnswerTypeOptions: Array<SelectOption>;
  isEdit: boolean;
  isPending: boolean;
  customAnswerTypeId: string;
  namePrefix?: string;
  isMainQuestion?: boolean;
}

export const QuestionFields = ({
  selectAnswerTypeOptions,
  isEdit,
  isPending,
  customAnswerTypeId,
  namePrefix = '',
  isMainQuestion = false,
}: QuestionFieldsProps) => {
  const { setFieldValue, getFieldMeta } = useFormikContext<Question>();
  const isCustom = getFieldMeta(namePrefix + 'isCustom').value;

  return (
    <>
      <TextField label="Frage" name={`${namePrefix}question`} required={true} />
      <TextareaField label="Beschreibung (mehrzeilig)" name="description" />
      <SelectField
        label="Antworttyp"
        name={`${namePrefix}answerTypeId`}
        disabled={isEdit}
        options={selectAnswerTypeOptions}
        onChange={(value) => {
          setFieldValue(
            `${namePrefix}isCustom`,
            getIsCustomAnswerType(customAnswerTypeId, value as string),
          );
        }}
        required={true}
      />
      {isCustom && !isMainQuestion && (
        <>
          <Typography align="center">Nutzerdefinierte Antworten:</Typography>
          <CustomAnswersForm
            name={`${namePrefix}answers`}
            isPending={isPending}
          />
        </>
      )}

      {isCustom && isMainQuestion && (
        <>
          <Typography
            variant="h6"
            component="h3"
            gutterBottom={true}
            align="center"
          >
            Nutzerdefinierte Antworten
          </Typography>

          <Paper>
            <Box p="1rem" textAlign="center">
              <CustomAnswersForm
                name={`${namePrefix}answers`}
                isPending={isPending}
              />
            </Box>
          </Paper>
        </>
      )}
    </>
  );
};
