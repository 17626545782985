import { isSameDay, isSameMonth, isSameWeek } from 'date-fns';
import { ResultPeriod } from '../../constants';
import { SurveyAnswer } from '../../types';

function sameDateFilter(date: Date) {
  return (answer: SurveyAnswer) => isSameDay(answer.date, date);
}

function sameWeekFilter(date: Date) {
  return (answer: SurveyAnswer) =>
    isSameWeek(answer.date, date, {
      weekStartsOn: 1,
    });
}

function sameMonthFilter(date: Date) {
  return (answer: SurveyAnswer) => isSameMonth(answer.date, date);
}

export function byPeriod({ type, date }: { type: ResultPeriod; date: Date }) {
  switch (type) {
    case ResultPeriod.MONTH_RESULTS_PERIOD:
      return sameMonthFilter(date);
    case ResultPeriod.WEEK_RESULTS_PERIOD:
      return sameWeekFilter(date);
    case ResultPeriod.DAY_RESULTS_PERIOD:
      return sameDateFilter(date);
    default:
      return () => true;
  }
}
