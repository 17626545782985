import { List, ListItem, ListItemIcon, Grid } from '@material-ui/core';
import {
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
  CheckCircle as CheckCircleIcon,
} from '@material-ui/icons';
import { getLegendEntries } from '../../containers/helpers/getLegendEntries';

interface ColumnProps {
  entries: ReturnType<typeof getLegendEntries>;
  execUpdateDataKeyState: (state: Record<string, boolean>) => void;
}

const Column = ({ entries, execUpdateDataKeyState }: ColumnProps) => (
  <List>
    {entries.map(({ LabelComponent, color, state, label }, index) => (
      <ListItem
        key={`${index}_${color}`}
        button
        onClick={() => execUpdateDataKeyState({ [label]: !state })}
      >
        <ListItemIcon>
          {state ? (
            <CheckCircleIcon fontSize="large" style={{ color }} />
          ) : (
            <RadioButtonUncheckedIcon fontSize="large" style={{ color }} />
          )}
        </ListItemIcon>

        <LabelComponent />
      </ListItem>
    ))}
  </List>
);

interface LegendProps {
  hasTwoColumns: boolean;
  leftEntries: ReturnType<typeof getLegendEntries>;
  rightEntries: ReturnType<typeof getLegendEntries>;
  execUpdateDataKeyState: (state: Record<string, boolean>) => void;
}

export const Legend = ({
  hasTwoColumns,
  leftEntries,
  rightEntries,
  execUpdateDataKeyState,
}: LegendProps) => {
  return (
    <Grid container>
      <Grid item xs={hasTwoColumns ? 6 : 12}>
        <Column
          entries={leftEntries}
          execUpdateDataKeyState={execUpdateDataKeyState}
        />
      </Grid>
      {hasTwoColumns && (
        <Grid item xs={6}>
          <Column
            entries={rightEntries}
            execUpdateDataKeyState={execUpdateDataKeyState}
          />
        </Grid>
      )}
    </Grid>
  );
};
