import { Box, Typography } from '@material-ui/core';
import { TextareaField, TextField } from '@gts-common/client';

interface Props {
  name: string;
}

export function LinkPanel({ name }: Props) {
  return (
    <Box>
      <Typography>
        Mit der Empfehlungsseite besteht die Möglichkeit, z. B. bei einer
        positiven Bewertung, den Kunden dazu zu animieren, auch direkt eine
        positive Bewertung z. B. bei Google zu hinterlassen.
      </Typography>

      <TextField
        label="Überschrift"
        name={`${name}.headline`}
        required={true}
      />
      <TextareaField label="Beschreibung" name={`${name}.description`} />
      <TextField
        label="Text für Weiterleitungsbutton"
        name={`${name}.urlDescription`}
        required={true}
      />
      <TextField
        label="URL für Weiterleitungsbutton"
        name={`${name}.url`}
        required={true}
      />
    </Box>
  );
}
