import { Box, Paper } from '@material-ui/core';

import { SelectOption } from '@gts-common/client';
import { SelectedConditional } from '../../../constants';
import { EditSurvey } from '../../../types';
import { LinkPanel } from './Panels/LinkPanel';
import { QuestionPanel } from './Panels/QuestionPanel';
import { RadioButtons } from './RadioButtons';
import { NonePanel } from './Panels/NonePanel';
import { SurveyNotPendingWarningPanel } from './Panels/SurveyNotPendingWarningPanel';

interface Props {
  selectAnswerTypeOptions: Array<SelectOption>;
  isPending: boolean;
  customAnswerTypeId: string;
  answers: EditSurvey['answers'];
  selectedAnswerTab: number;
}

function getPanelContents(
  selectedConditional: SelectedConditional,
  isPending: boolean,
  index: number,
  customAnswerTypeId: string,
  selectAnswerTypeOptions: Array<SelectOption>,
) {
  if (!isPending && selectedConditional === SelectedConditional.NONE) {
    return <SurveyNotPendingWarningPanel />;
  }

  if (selectedConditional === SelectedConditional.NONE) {
    return <NonePanel />;
  } else if (selectedConditional === SelectedConditional.LINK) {
    return <LinkPanel name={`answers.${index}.conditionalLink`} />;
  } else {
    return (
      <QuestionPanel
        isPending={isPending}
        customAnswerTypeId={customAnswerTypeId}
        selectAnswerTypeOptions={selectAnswerTypeOptions}
        name={`answers.${index}.conditionalQuestion`}
      />
    );
  }
}

export function SurveyAnswerPanels({
  selectAnswerTypeOptions,
  isPending,
  customAnswerTypeId,
  answers,
  selectedAnswerTab,
}: Props) {
  return (
    <Paper>
      <Box mt="1rem" p="1rem" textAlign="center">
        {answers
          .map((answer, index) => {
            return (
              <div key={answer.answerTypeAnswerId}>
                <RadioButtons
                  name={`answers.${index}.selectedConditional`}
                  disabled={!isPending}
                />
                {getPanelContents(
                  answer.selectedConditional,
                  isPending,
                  index,
                  customAnswerTypeId,
                  selectAnswerTypeOptions,
                )}
              </div>
            );
          })
          .filter((_: unknown, index: number) => {
            // Only show the conditionals of the selected tab
            return selectedAnswerTab === index;
          })}
      </Box>
    </Paper>
  );
}
