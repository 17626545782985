import { AnswerTypeRecord } from '../../types';

export function prepareAnswerTypeSelectOptions(
  answerTypes: Array<AnswerTypeRecord>,
) {
  return answerTypes.map((answerType) => ({
    value: answerType.answerTypeId,
    label: answerType.name,
  }));
}
