import { Box, Button, Popover } from '@material-ui/core';
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from '@material-ui/icons';
import { useState, MouseEvent } from 'react';
import { CLIENT_DATE_FORMAT } from '@gts-common/client-server';
import {
  addDays,
  addMonths,
  getMonth,
  getYear,
  format,
  startOfWeek,
  endOfWeek,
} from 'date-fns';
import { ResultPeriod, MONTHS, WEEKDAYS_SHORT } from '../../../constants';
import Datepicker from './Datepicker';

interface BasePickerProps {
  leftAction: () => void;
  rightAction: () => void;
  centralLabel: string;
  execChangeDate: (date: Date) => void;
}

const BasePicker = ({
  leftAction,
  rightAction,
  centralLabel,
  execChangeDate,
}: BasePickerProps) => {
  const [anchorEl, setAnchorEl] = useState<MouseEvent['currentTarget'] | null>(
    null,
  );

  const handlePopoverOpen = (event: MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Popover
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Datepicker
          onChange={(date) => {
            execChangeDate(date);
            handlePopoverClose();
          }}
        />
      </Popover>
      <Box display="flex" justifyContent="center">
        <Button
          color="secondary"
          variant="contained"
          size="small"
          onClick={leftAction}
        >
          <ChevronLeftIcon />
        </Button>
        <Button onClick={handlePopoverOpen}>{centralLabel}</Button>
        <Button
          color="secondary"
          variant="contained"
          size="small"
          onClick={rightAction}
        >
          <ChevronRightIcon />
        </Button>
      </Box>
    </>
  );
};

interface DayPickerProps {
  selectedDate: Date;
  execChangeDate: (date: Date) => void;
}

const weekdays = WEEKDAYS_SHORT.de;
const DayPicker = ({ selectedDate, execChangeDate }: DayPickerProps) => (
  <BasePicker
    leftAction={() => execChangeDate(addDays(selectedDate, -1))}
    rightAction={() => execChangeDate(addDays(selectedDate, 1))}
    centralLabel={`${format(selectedDate, CLIENT_DATE_FORMAT)} (${
      weekdays[Number(format(selectedDate, 'i')) - 1]
    })`}
    execChangeDate={execChangeDate}
  />
);

interface WeekPickerProps {
  selectedDate: Date;
  execChangeDate: (date: Date) => void;
}

const WeekPicker = ({ selectedDate, execChangeDate }: WeekPickerProps) => (
  <BasePicker
    leftAction={() => execChangeDate(addDays(selectedDate, -7))}
    rightAction={() => execChangeDate(addDays(selectedDate, 7))}
    centralLabel={`KW: ${format(selectedDate, 'ww')} (${format(
      startOfWeek(selectedDate),
      'dd.MM.yy',
    )} - ${format(endOfWeek(selectedDate), 'dd.MM.yy')})`}
    execChangeDate={execChangeDate}
  />
);

interface MonthPickerProps {
  selectedDate: Date;
  execChangeDate: (date: Date) => void;
}

const MonthPicker = ({ selectedDate, execChangeDate }: MonthPickerProps) => (
  <BasePicker
    leftAction={() => execChangeDate(addMonths(selectedDate, -1))}
    rightAction={() => execChangeDate(addMonths(selectedDate, 1))}
    centralLabel={`${MONTHS.de[getMonth(selectedDate)]} ${getYear(
      selectedDate,
    )}`}
    execChangeDate={execChangeDate}
  />
);

interface PickerProps {
  periodType: ResultPeriod;
  selectedDate: Date;
  execChangeDate: (date: Date) => void;
}

export const Picker = ({
  periodType,
  selectedDate,
  execChangeDate,
}: PickerProps) => {
  switch (periodType) {
    case ResultPeriod.MONTH_RESULTS_PERIOD:
      return (
        <MonthPicker
          selectedDate={selectedDate}
          execChangeDate={execChangeDate}
        />
      );
    case ResultPeriod.WEEK_RESULTS_PERIOD:
      return (
        <WeekPicker
          selectedDate={selectedDate}
          execChangeDate={execChangeDate}
        />
      );
    case ResultPeriod.DAY_RESULTS_PERIOD:
      return (
        <DayPicker
          selectedDate={selectedDate}
          execChangeDate={execChangeDate}
        />
      );
    default:
      return (
        <WeekPicker
          selectedDate={selectedDate}
          execChangeDate={execChangeDate}
        />
      );
  }
};
