import {
  Box,
  ListItem,
  makeStyles,
  Grid,
  createStyles,
  WithStyles,
  Theme,
} from '@material-ui/core';

import { FiberManualRecord as CircleIcon } from '@material-ui/icons';
import { TooltipProps } from 'recharts';

const styles = (theme: Theme) =>
  createStyles({
    listItem: {
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: 14,
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 16,
      },
    },
  });
const useStylesHook = makeStyles(styles);

interface BarTooltipColumnProps extends WithStyles<typeof styles> {
  entries: TooltipProps<number, string>['payload'];
  colors: (num: number) => string;
  colorIndex: Record<string, number>;
}

function BarTooltipColumn({
  entries,
  classes,
  colors,
  colorIndex,
}: BarTooltipColumnProps) {
  if (!entries) {
    return null;
  }

  return (
    <>
      {entries.map((entry) => {
        return (
          <ListItem className={classes.listItem} key={entry.name}>
            <CircleIcon
              style={{ color: colors(colorIndex[entry.name ?? '']) }}
            />
            {entry.value} {entry.value === 1 ? 'Stimme' : 'Stimmen'}
          </ListItem>
        );
      })}
    </>
  );
}

interface PrimaryTooltipProps extends TooltipProps<number, string> {
  colors: (num: number) => string;
}

export function PrimaryTooltip({
  payload,
  active,
  colors,
}: PrimaryTooltipProps) {
  const classes = useStylesHook();

  if (!payload || payload.length === 0) {
    return null;
  }

  let leftEntries: typeof payload;
  let rightEntries: typeof payload;
  const hasTwoColumns = payload.length >= 6;

  if (hasTwoColumns) {
    const breakpoint = Math.ceil(payload.length / 2);
    leftEntries = payload.slice(0, breakpoint);
    rightEntries = payload.slice(breakpoint);
  } else {
    leftEntries = payload;
    rightEntries = [];
  }

  if (!active) {
    return null;
  } else {
    const colorIndex = Object.keys(payload[0].payload)
      .filter((key) => key !== 'label')
      .reduce((prev, label, index) => ({ ...prev, [label]: index }), {});

    return (
      <Box bgcolor="white" p="0.5rem" boxShadow={3} borderRadius="borderRadius">
        <Grid container>
          <Grid item xs={hasTwoColumns ? 6 : 12}>
            <BarTooltipColumn
              entries={leftEntries}
              classes={classes}
              colors={colors}
              colorIndex={colorIndex}
            />
          </Grid>
          {hasTwoColumns && (
            <Grid item xs={6}>
              <BarTooltipColumn
                entries={rightEntries}
                classes={classes}
                colors={colors}
                colorIndex={colorIndex}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    );
  }
}

export function PieTooltip({ payload }: TooltipProps<number, string>) {
  const classes = useStylesHook();

  if (!payload) {
    return null;
  }

  return (
    <Box bgcolor="white" p="0.5rem" boxShadow={3} borderRadius="borderRadius">
      {payload.map(({ payload: { value, fill } }) => (
        <ListItem key={`${value}_${fill}`} className={classes.listItem}>
          <CircleIcon style={{ color: fill }} />
          {value} {value === 1 ? 'Stimme' : 'Stimmen'}
        </ListItem>
      ))}
    </Box>
  );
}
