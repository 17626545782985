import {
  Divider,
  IconButton,
  List,
  ListItem,
  SwipeableDrawer,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
  FormLabel,
  Checkbox,
  Theme,
  createStyles,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import { ChevronLeft as ChevronLeftIcon } from '@material-ui/icons';
import {
  ResultPeriod,
  ResultView,
  RESULT_PERIODS,
  RESULT_VIEWS,
  ChartType,
} from '../../constants';

// Use an extra function to get the mixins because
// having those in the createStyles object results in a type error
function getMixins(theme: Theme): Record<string, unknown> {
  return theme.mixins.toolbar;
}

const styles = (theme: Theme) =>
  createStyles({
    list: {
      width: 250,
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      ...getMixins(theme),
    },
  });

interface Props extends WithStyles<typeof styles> {
  view: ResultView;
  periodType: ResultPeriod;
  open: boolean;
  primaryChartType: ChartType;
  showSecondaryChart: boolean;
  execChangePeriodType: (
    periodType: ResultPeriod,
    viewType: ResultView,
  ) => void;
  execChangeView: (viewType: ResultView) => void;
  toggleDrawer: (isOpen: boolean) => void;
  execChangePrimaryChartType: (chartType: ChartType) => void;
  execToggleSecondaryChart: (show: boolean) => void;
}

const UnstyledSurveyResultsDrawer = ({
  view,
  periodType,
  open,
  toggleDrawer,
  execChangePeriodType,
  execChangeView,
  primaryChartType,
  execChangePrimaryChartType,
  showSecondaryChart,
  execToggleSecondaryChart,
  classes,
}: Props) => {
  return (
    <SwipeableDrawer
      variant="temporary"
      open={open}
      onOpen={() => toggleDrawer(true)}
      onClose={() => toggleDrawer(false)}
    >
      <div className={classes.toolbar}>
        <IconButton onClick={() => toggleDrawer(false)}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <div className={classes.list} role="presentation">
        <List>
          <ListItem>
            <FormControl component="fieldset">
              <FormLabel component="legend">Datenzeitraum</FormLabel>
              <RadioGroup
                name="period"
                value={periodType}
                onChange={(event) => {
                  const periodIndex = RESULT_PERIODS.indexOf(
                    event.target.value as ResultPeriod,
                  );
                  const newView =
                    periodIndex < RESULT_VIEWS.indexOf(view)
                      ? RESULT_VIEWS[periodIndex]
                      : view;
                  execChangePeriodType(
                    event.target.value as ResultPeriod,
                    newView,
                  );
                }}
              >
                <FormControlLabel
                  value={ResultPeriod.DAY_RESULTS_PERIOD}
                  control={<Radio color="primary" />}
                  label="Ein Tag"
                />
                <FormControlLabel
                  value={ResultPeriod.WEEK_RESULTS_PERIOD}
                  control={<Radio color="primary" />}
                  label="Eine Woche"
                />
                <FormControlLabel
                  value={ResultPeriod.MONTH_RESULTS_PERIOD}
                  control={<Radio color="primary" />}
                  label="Ein Monat"
                />
              </RadioGroup>
            </FormControl>
          </ListItem>
          <ListItem>
            <FormControl component="fieldset">
              <FormLabel component="legend">Ansichten</FormLabel>
              <RadioGroup
                name="view"
                value={view}
                onChange={(event) =>
                  execChangeView(event.target.value as ResultView)
                }
              >
                <FormControlLabel
                  value={ResultView.DAY_RESULTS_VIEW}
                  control={<Radio color="primary" />}
                  label="Tagesansicht"
                />
                <FormControlLabel
                  value={ResultView.WEEK_RESULTS_VIEW}
                  control={<Radio color="primary" />}
                  label="Wochenansicht"
                  disabled={
                    RESULT_PERIODS.indexOf(periodType) <
                    RESULT_VIEWS.indexOf(ResultView.WEEK_RESULTS_VIEW)
                  }
                />
                <FormControlLabel
                  value={ResultView.MONTH_RESULTS_VIEW}
                  control={<Radio color="primary" />}
                  label="Monatsansicht"
                  disabled={
                    RESULT_PERIODS.indexOf(periodType) <
                    RESULT_VIEWS.indexOf(ResultView.MONTH_RESULTS_VIEW)
                  }
                />
              </RadioGroup>
            </FormControl>
          </ListItem>
          <ListItem>
            <FormControl component="fieldset">
              <FormLabel component="legend">Diagramme</FormLabel>
              <RadioGroup
                name="primaryChartType"
                value={primaryChartType}
                onChange={(event) =>
                  execChangePrimaryChartType(event.target.value as ChartType)
                }
              >
                <FormControlLabel
                  value={ChartType.BAR_CHART}
                  control={<Radio color="primary" />}
                  label="Balkendiagramm"
                />
                <FormControlLabel
                  value={ChartType.LINE_CHART}
                  control={<Radio color="primary" />}
                  label="Liniendiagramm"
                />
              </RadioGroup>
            </FormControl>
          </ListItem>
          <ListItem>
            <FormControl component="fieldset">
              <FormLabel component="legend">Summierte Daten</FormLabel>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showSecondaryChart}
                    onChange={(_event, second) =>
                      execToggleSecondaryChart(second)
                    }
                    value={showSecondaryChart}
                    color="primary"
                  />
                }
                label="Kuchendiagramm"
              />
            </FormControl>
          </ListItem>
        </List>
      </div>
    </SwipeableDrawer>
  );
};

export const SurveyResultsDrawer = withStyles(styles)(
  UnstyledSurveyResultsDrawer,
);
