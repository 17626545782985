import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ChangeEvent } from 'react';
import { ListSurvey, SurveyStatus } from '@gts-ns/utils';
import { SurveyList } from './SurveyList';

interface Props {
  title: string;
  surveys: Array<ListSurvey>;
  changeStatus: (surveyId: string, status: SurveyStatus) => void;
  showSurveyEdit: (surveyId: string) => void;
  showSurveyResults: (surveyId: string) => void;
  expanded: boolean;
  onChange: (event: ChangeEvent<unknown>, expanded: boolean) => void;
  showSurveyQr: (surveyId: string) => void;
}

export const SurveySection = ({
  title,
  surveys,
  changeStatus,
  showSurveyEdit,
  showSurveyResults,
  expanded,
  onChange,
  showSurveyQr,
}: Props) => {
  return (
    <Accordion expanded={expanded} onChange={onChange}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6" component="h2">
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <SurveyList
          surveys={surveys}
          changeStatus={changeStatus}
          showSurveyEdit={showSurveyEdit}
          showSurveyResults={showSurveyResults}
          showSurveyQr={showSurveyQr}
        />
      </AccordionDetails>
    </Accordion>
  );
};
