import { serverComm } from '@gts-ns/ui';
import { AddSurveyRequestBody, AddSurveyResponse, SURVEY } from '@gts-ns/utils';
import {
  getErrorModalMessage,
  sendErrorToServer,
  serverRequestFailed,
  serverRequestSucceeded,
  showError,
  startServerRequest,
} from '@gts-common/client';
import {
  Actions,
  SURVEY_ADDED,
  SurveyAddedAction,
  Thunk,
} from '../reduxActionTypes';
import { AddSurvey } from '../../types';
import { prepareQuestionForServer } from '../helpers/prepareQuestionForServer';
import { getOperationFailureReason } from '../helpers/getOperationFailureReason';
import { execReplace } from '../navigation';
import { SURVEY_EDIT_PATH } from '../../constants';

function surveyAdded(survey: AddSurveyResponse): SurveyAddedAction {
  return {
    type: SURVEY_ADDED,
    payload: survey,
  };
}

export function addSurvey(addSurvey: AddSurvey): Thunk<Actions> {
  return (dispatch) => {
    const { isCustom, ...survey } = addSurvey;
    const trimmedQuestionData = prepareQuestionForServer(addSurvey, isCustom);

    const trimmedData: AddSurveyRequestBody = {
      ...survey,
      ...trimmedQuestionData,
      name: survey.name.trim(),
      thankYouText: survey.thankYouText.trim(),
    };

    dispatch(startServerRequest());
    serverComm
      .execPostRequest<AddSurveyResponse, AddSurveyRequestBody>(
        SURVEY,
        trimmedData,
      )
      .then(
        (resp) => {
          if (resp.succeeded) {
            dispatch(serverRequestSucceeded('Umfrage hinzugefügt.'));
            dispatch(surveyAdded(resp.body));
            dispatch(execReplace(`${SURVEY_EDIT_PATH}/${resp.body.surveyId}`));
          } else {
            dispatch(serverRequestFailed(getOperationFailureReason(resp)));
          }
        },
        (e: unknown) => {
          dispatch(serverRequestFailed(getErrorModalMessage(e)));
        },
      )
      .catch((e: unknown) => {
        sendErrorToServer(serverComm, e);
        dispatch(showError(getErrorModalMessage(e)));
      });
  };
}
