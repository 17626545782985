import { ResultView } from '../../constants';
import { SurveyAnswer } from '../../types';

function getResults(answers: Array<SurveyAnswer>, labels: Array<string>) {
  return labels.map((label) => ({
    label,
    value: answers
      .filter((answer) => answer.label === label)
      .reduce((prev, curr) => prev + curr.numOfAnswers, 0),
  }));
}

function getSingleEntry(
  label: string,
  answers: Array<SurveyAnswer>,
  subLabels: Array<string>,
) {
  return {
    label,
    ...getResults(answers, subLabels).reduce(
      (prev, curr) => ({ ...prev, [curr.label]: curr.value }),
      {},
    ),
  };
}
function getMonthResults(
  answers: Array<SurveyAnswer>,
  labels: Array<string>,
  daysInMonth: number,
) {
  const days = Array(daysInMonth)
    .fill('')
    .map((_hour, i) => `${i + 1}.`);
  return days.map((dayLabel, i) => {
    const answersForDay = answers.filter(
      (answer) => answer.dayOfMonth === i + 1,
    );
    return getSingleEntry(dayLabel, answersForDay, labels);
  });
}

function getWeekResults(answers: Array<SurveyAnswer>, labels: Array<string>) {
  const weekdays = ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'];
  // weekdays: 0-7: monday-sunday
  return weekdays.map((dayLabel, i) => {
    const answersForDay = answers.filter((answer) => answer.weekday === i + 1);
    return getSingleEntry(dayLabel, answersForDay, labels);
  });
}

function getDayResults(answers: Array<SurveyAnswer>, labels: Array<string>) {
  const hours = Array(24)
    .fill('')
    .map((_hour, i) => `${i}h`);
  return hours.map((hour, i) => {
    const answersForHour = answers.filter((answer) => answer.hour === i);

    return getSingleEntry(hour, answersForHour, labels);
  });
}

export function formatAnswersByView(
  view: ResultView | undefined,
  answers: Array<SurveyAnswer>,
  labels: Array<string>,
  daysInMonth?: number,
) {
  switch (view) {
    case ResultView.MONTH_RESULTS_VIEW: {
      if (daysInMonth === undefined) {
        throw new Error('daysInMonth is undefined');
      }

      return getMonthResults(answers, labels, daysInMonth);
    }
    case ResultView.WEEK_RESULTS_VIEW:
      return getWeekResults(answers, labels);
    case ResultView.DAY_RESULTS_VIEW:
      return getDayResults(answers, labels);
    default:
      return getResults(answers, labels);
  }
}
