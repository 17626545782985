import { initialState } from '../initialState';
import {
  Actions,
  LOGIN_CHECK_SUCCEEDED,
  LOGIN_SUCCEEDED,
} from '../actions/reduxActionTypes';
import { AnswerTypesState } from '../types';

export function answerTypesReducer(
  state = initialState.answerTypes,
  action: Actions,
): AnswerTypesState {
  switch (action.type) {
    case LOGIN_SUCCEEDED:
      return {
        ...state,
        answerTypes: action.payload.answerTypes,
      };
    case LOGIN_CHECK_SUCCEEDED:
      return {
        ...state,
        answerTypes: action.payload.answerTypes,
      };
    default:
      return state;
  }
}
