import { addDays, addMonths } from 'date-fns';
import { ResultPeriod } from '../../../constants';

function swipe(
  periodType: ResultPeriod,
  execChangeDate: (date: Date) => void,
  selectedDate: Date,
  direction: 1 | -1,
) {
  switch (periodType) {
    case ResultPeriod.MONTH_RESULTS_PERIOD:
      execChangeDate(addMonths(selectedDate, direction));
      break;
    case ResultPeriod.WEEK_RESULTS_PERIOD:
      execChangeDate(addDays(selectedDate, 7 * direction));
      break;
    case ResultPeriod.DAY_RESULTS_PERIOD:
      execChangeDate(addDays(selectedDate, direction));
      break;
    default:
      execChangeDate(addDays(selectedDate, direction));
      break;
  }
}

export function swipeLeft(
  periodType: ResultPeriod,
  execChangeDate: (date: Date) => void,
  selectedDate: Date,
) {
  // next
  swipe(periodType, execChangeDate, selectedDate, 1);
}

export function swipeRight(
  periodType: ResultPeriod,
  execChangeDate: (date: Date) => void,
  selectedDate: Date,
) {
  // previous
  swipe(periodType, execChangeDate, selectedDate, -1);
}
