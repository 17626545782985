import { AnswerTypeType } from '@gts-ns/utils';
import { ChartType } from '../../../constants';
import { CustomBarChart } from './CustomBarChart';
import { CustomLineChart } from './CustomLineChart';

interface Props {
  type: ChartType;
  answerType: AnswerTypeType;
  answers: Array<{ label: string }>;
  dataKeys: Array<string>;
  colors: (num: number) => string;
  dataKeyStates: Record<string, boolean>;
}

export const PrimaryChart = ({
  type,
  answers,
  dataKeys,
  answerType,
  colors,
  dataKeyStates,
}: Props) => {
  switch (type) {
    case ChartType.BAR_CHART:
      return (
        <CustomBarChart
          answers={answers}
          answerType={answerType}
          dataKeys={dataKeys}
          colors={colors}
          dataKeyStates={dataKeyStates}
        />
      );
    case ChartType.LINE_CHART:
      return (
        <CustomLineChart
          answers={answers}
          answerType={answerType}
          dataKeys={dataKeys}
          colors={colors}
          dataKeyStates={dataKeyStates}
        />
      );
    default:
      return (
        <CustomBarChart
          answers={answers}
          answerType={answerType}
          dataKeys={dataKeys}
          colors={colors}
          dataKeyStates={dataKeyStates}
        />
      );
  }
};
