import { AppBar, Box, Typography } from '@material-ui/core';
import { AnswerTypeType, SurveyStatus } from '@gts-ns/utils';

import { SelectOption } from '@gts-common/client';
import { useState } from 'react';
import { setNestedObjectValues } from 'formik';
import { FormikErrors, FormikTouched } from 'formik/dist/types';
import { EditSurvey } from '../../../types';
import { SurveyAnswerPanels } from './SurveyAnswerPanels';
import { SurveyAnswersTabs } from './SurveyAnswersTabs';

interface Props {
  answerTypeType: AnswerTypeType;
  mainQuestionId: EditSurvey['questionId'];
  surveyStatus: EditSurvey['status'];
  customAnswerTypeId: string;
  selectAnswerTypeOptions: Array<SelectOption>;
  formikAnswers: EditSurvey['answers'];
  setTouched: (
    touched: FormikTouched<EditSurvey>,
    shouldValidate?: boolean,
  ) => void;
  errors: FormikErrors<EditSurvey>;
}

export const Conditionals = ({
  answerTypeType,
  surveyStatus,
  customAnswerTypeId,
  selectAnswerTypeOptions,
  formikAnswers,
  setTouched,
  errors,
}: Props) => {
  const [selectedAnswerTab, setSelectedAnswerTab] = useState<number>(0);

  function selectTab(tab: number) {
    if (!(errors.answers && errors.answers[selectedAnswerTab])) {
      setSelectedAnswerTab(tab);
    } else {
      // Touch all error fields to display the error messages
      setTouched(setNestedObjectValues(errors, true), false);
    }
  }

  return (
    <>
      <Box mt="1rem">
        <Typography
          variant="h6"
          component="h3"
          gutterBottom={true}
          align="center"
        >
          Nachfragen und Empfehlungsseiten
        </Typography>
        <AppBar position="static" color="inherit">
          <SurveyAnswersTabs
            answerTypeType={answerTypeType}
            answers={formikAnswers}
            value={selectedAnswerTab}
            setValue={selectTab}
          />
        </AppBar>
      </Box>
      <SurveyAnswerPanels
        selectAnswerTypeOptions={selectAnswerTypeOptions}
        isPending={surveyStatus === SurveyStatus.PENDING}
        customAnswerTypeId={customAnswerTypeId}
        answers={formikAnswers}
        selectedAnswerTab={selectedAnswerTab}
      />
    </>
  );
};
