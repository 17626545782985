import { useEffect } from 'react';
import { SelectOption, View, PleaseWaitText } from '@gts-common/client';
import { AnswerTypeType } from '@gts-ns/utils';
import { HOME_VIEW } from '../../constants';
import { EditSurvey } from '../../types';
import { SurveyEdit } from './SurveyEdit';

interface Props {
  surveyId: string | undefined;
  survey: EditSurvey | undefined;
  selectAnswerTypeOptions: Array<SelectOption>;
  widgetUrl: string;
  answerTypeType: AnswerTypeType;
  customAnswerTypeId: string;
  showSurveyResults: (surveyId: string) => void;
  execEditSurvey: (surveyId: string, surveyUpdate: EditSurvey) => void;
  loadSurvey: (surveyId: string) => void;
}

export const SurveyEditView = ({
  surveyId,
  survey,
  selectAnswerTypeOptions,
  widgetUrl,
  answerTypeType,
  customAnswerTypeId,
  showSurveyResults,
  execEditSurvey,
  loadSurvey,
}: Props) => {
  useEffect(() => {
    if (surveyId) {
      loadSurvey(surveyId);
    }
  }, [surveyId, loadSurvey]);

  if (survey && survey.surveyId === surveyId) {
    return (
      <View
        hasBackButton={true}
        navigateBackTo={HOME_VIEW}
        backButtonLabel="Startseite"
        title="Umfrage bearbeiten"
      >
        <SurveyEdit
          survey={survey}
          selectAnswerTypeOptions={selectAnswerTypeOptions}
          widgetUrl={widgetUrl}
          answerTypeType={answerTypeType}
          customAnswerTypeId={customAnswerTypeId}
          showSurveyResults={showSurveyResults}
          execEditSurvey={execEditSurvey}
        />
      </View>
    );
  } else {
    return (
      <View
        hasBackButton={true}
        navigateBackTo={HOME_VIEW}
        backButtonLabel="Startseite"
        title="Umfrage bearbeiten"
      >
        <PleaseWaitText />{' '}
      </View>
    );
  }
};
