import { SelectOption } from '@gts-common/client';
import { QuestionFields } from '../../QuestionFields';

interface Props {
  selectAnswerTypeOptions: Array<SelectOption>;
  isPending: boolean;
  customAnswerTypeId: string;
  name: string;
}

export function QuestionPanel({
  selectAnswerTypeOptions,
  isPending,
  customAnswerTypeId,
  name,
}: Props) {
  return (
    <QuestionFields
      isEdit={!isPending}
      isPending={isPending}
      selectAnswerTypeOptions={selectAnswerTypeOptions}
      customAnswerTypeId={customAnswerTypeId}
      namePrefix={`${name}.`}
    />
  );
}
