export const defaultCustomAnswers = [
  { label: '', answerTypeAnswerId: 'first' },
  { label: '', answerTypeAnswerId: 'second' },
];

export const getDefaultConditionalQuestion = (answerTypeId: string) => ({
  question: '',
  description: '',
  answers: [...defaultCustomAnswers],
  answerTypeId,
  isCustom: true,
});
