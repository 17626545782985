import {
  CHANGE_SURVEY_RESULTS_PERIOD_FIELDS,
  CHANGE_SURVEY_RESULTS_VIEW,
  ChangeSurveyResultsPeriodFieldsAction,
  ChangeSurveyResultsViewAction,
} from '../reduxActionTypes';
import { ResultPeriod, ResultView } from '../../constants';
import { PeriodChange } from '../../types';

export function changeView(view: ResultView): ChangeSurveyResultsViewAction {
  return {
    type: CHANGE_SURVEY_RESULTS_VIEW,
    payload: view,
  };
}

export function changePeriod(
  payload: PeriodChange,
): ChangeSurveyResultsPeriodFieldsAction {
  return {
    type: CHANGE_SURVEY_RESULTS_PERIOD_FIELDS,
    payload,
  };
}

export function execChangeDate(date: Date) {
  return changePeriod({ date });
}
export function execChangePeriodType(type: ResultPeriod, view: ResultView) {
  return changePeriod({ type, view });
}
