import { FieldArrayRenderProps } from 'formik';
import { Grid, IconButton } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';

interface MoveArrowsProps {
  values: Array<unknown>;
  arrayHelpers: FieldArrayRenderProps;
}

export const MoveArrows = ({ values, arrayHelpers }: MoveArrowsProps) => (
  <>
    {values.map((_, index) => (
      <Grid container key={index} alignItems="center">
        <Grid item xs={6}>
          <IconButton
            color="primary"
            disabled={index === values.length - 1}
            onClick={() => arrayHelpers.swap(index, index + 1)}
          >
            <KeyboardArrowDown />
          </IconButton>
        </Grid>
        <Grid item xs={6}>
          <IconButton
            color="primary"
            disabled={index === 0}
            onClick={() => arrayHelpers.swap(index, index - 1)}
          >
            <KeyboardArrowUp />
          </IconButton>
        </Grid>
      </Grid>
    ))}
  </>
);
