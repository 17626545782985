import {
  smileys as smileyColors,
  filledStar as filledStarColor,
  thumbs as thumbColors,
  grades as gradeColors,
  oneToTen as oneToTenColors,
} from '@gts-ns/ui';
import { AnswerTypeType } from '@gts-ns/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFrown,
  faMeh,
  faSmile,
  faStar,
  faThumbsDown,
  faThumbsUp,
} from '@fortawesome/free-solid-svg-icons';
import { Box } from '@material-ui/core';

const smileys = [faSmile, faMeh, faFrown];
const thumbs = [faThumbsDown, faThumbsUp];

export function getAnswerTypeTabProps(
  answerTypeType: AnswerTypeType,
  answerIndex: number,
  label: string,
  classes: Record<string, string>,
) {
  switch (answerTypeType) {
    case AnswerTypeType.SMILEYS:
      return {
        icon: (
          <FontAwesomeIcon
            className={classes.icon}
            icon={smileys[answerIndex]}
            color={smileyColors(answerIndex)}
          />
        ),
      };
    case AnswerTypeType.STARS:
      return {
        icon: (
          <Box mt="0.7rem" mb="0.7rem">
            {[0, 1, 2, 3, 4].map(
              (value, index) =>
                index <= answerIndex && (
                  <FontAwesomeIcon
                    className={classes.stars}
                    icon={faStar}
                    color={filledStarColor}
                    key={value}
                  />
                ),
            )}
          </Box>
        ),
      };
    case AnswerTypeType.THUMBS:
      return {
        icon: (
          <FontAwesomeIcon
            className={classes.icon}
            icon={thumbs[answerIndex]}
            color={thumbColors(answerIndex)}
          />
        ),
      };
    case AnswerTypeType.ONE_TO_SIX:
      return {
        label,
        color: gradeColors(answerIndex),
      };
    case AnswerTypeType.ONE_TO_TEN:
      return {
        label,
        color: oneToTenColors(answerIndex),
      };
    default:
      return { label };
  }
}
