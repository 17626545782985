import { ListSurvey, SurveyStatus } from '@gts-ns/utils';
import { AppState } from '../../types';
import { SurveySection } from './SurveySection';

export interface SurveysListProps {
  surveys: {
    [SurveyStatus.ACTIVE]: Array<ListSurvey>;
    [SurveyStatus.PENDING]: Array<ListSurvey>;
    [SurveyStatus.TERMINATED]: Array<ListSurvey>;
  };
  surveyExpandStates: AppState['surveyExpandStates'];
  showSurveyEdit: (surveyId: string) => void;
  showSurveyResults: (surveyId: string) => void;
  execChangeStatus: (surveyId: string, status: SurveyStatus) => void;
  expandSurveySection: (section: SurveyStatus, expanded: boolean) => void;
  showSurveyQr: (surveyId: string) => void;
}

export const SurveysList = ({
  surveys,
  surveyExpandStates,
  showSurveyEdit,
  showSurveyResults,
  execChangeStatus,
  expandSurveySection,
  showSurveyQr,
}: SurveysListProps) => {
  return (
    <>
      <SurveySection
        title="Aktive Umfragen"
        surveys={surveys[SurveyStatus.ACTIVE]}
        changeStatus={execChangeStatus}
        showSurveyEdit={showSurveyEdit}
        showSurveyResults={showSurveyResults}
        expanded={surveyExpandStates[SurveyStatus.ACTIVE]}
        onChange={(_event, expanded) =>
          expandSurveySection(SurveyStatus.ACTIVE, expanded)
        }
        showSurveyQr={showSurveyQr}
      />
      <SurveySection
        title="Umfragen in Bearbeitung"
        surveys={surveys[SurveyStatus.PENDING]}
        changeStatus={execChangeStatus}
        showSurveyEdit={showSurveyEdit}
        showSurveyResults={showSurveyResults}
        expanded={surveyExpandStates[SurveyStatus.PENDING]}
        onChange={(_event, expanded) =>
          expandSurveySection(SurveyStatus.PENDING, expanded)
        }
        showSurveyQr={showSurveyQr}
      />
      <SurveySection
        title="Beendete Umfragen"
        surveys={surveys[SurveyStatus.TERMINATED]}
        changeStatus={execChangeStatus}
        showSurveyEdit={showSurveyEdit}
        showSurveyResults={showSurveyResults}
        expanded={surveyExpandStates[SurveyStatus.TERMINATED]}
        onChange={(_event, expanded) =>
          expandSurveySection(SurveyStatus.TERMINATED, expanded)
        }
        showSurveyQr={showSurveyQr}
      />
    </>
  );
};
