import { initialState } from '../initialState';
import {
  GOT_SURVEY,
  LOGIN_CHECK_SUCCEEDED,
  LOGIN_SUCCEEDED,
  SURVEY_ADDED,
  SURVEY_UPDATED,
  Actions,
  SURVEY_STATUS_CHANGED,
} from '../actions/reduxActionTypes';
import { SurveysState } from '../types';

export function surveysReducer(
  state = initialState.surveys,
  action: Actions,
): SurveysState {
  switch (action.type) {
    case LOGIN_SUCCEEDED:
      return {
        ...state,
        surveys: action.payload.surveys,
      };

    case LOGIN_CHECK_SUCCEEDED:
      return {
        ...state,
        surveys: action.payload.surveys,
      };

    case GOT_SURVEY: {
      return {
        ...state,
        survey: action.payload,
      };
    }

    case SURVEY_ADDED:
      return {
        ...state,
        survey: undefined,
        surveys: [...state.surveys, action.payload],
      };

    case SURVEY_UPDATED:
      return {
        ...state,
        survey: undefined,
        surveys: state.surveys.map((survey) => {
          return survey.surveyId === action.payload.surveyId
            ? { ...survey, ...action.payload }
            : survey;
        }),
      };

    case SURVEY_STATUS_CHANGED:
      return {
        ...state,
        surveys: state.surveys.map((survey) => {
          return survey.surveyId === action.payload.surveyId
            ? { ...survey, ...action.payload }
            : survey;
        }),
      };

    default:
      return state;
  }
}
