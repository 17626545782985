import {
  MONTHS_LONG_NAME,
  WEEKDAYS_LONG_NAME,
  WEEKDAYS_SHORT_NAME,
} from '@gts-common/client-server';

export const HOME_VIEW = '/';

export const SURVEY_ADD_VIEW = '/add';
export const SURVEY_EDIT_PATH = '/edit';
export const SURVEY_EDIT_VIEW = `${SURVEY_EDIT_PATH}/:surveyId`;
export const SURVEY_QR_PATH = '/qr';
export const SURVEY_QR_VIEW = `${SURVEY_QR_PATH}/:surveyId`;
export const SURVEY_RESULTS_PATH = '/results';
export const SURVEY_RESULTS_VIEW = `${SURVEY_RESULTS_PATH}/:surveyId`;

export enum MainView {
  SURVEYS_VIEW = 'SURVEYS_VIEW',
  SURVEY_RESULTS_VIEW = 'SURVEY_RESULTS_VIEW',
}

export enum SubView {
  SURVEYS_LIST = 'SURVEYS_LIST',
  SURVEY_ADD = 'SURVEY_ADD',
  SURVEY_EDIT = 'SURVEY_EDIT',
}

// Result views
export enum ResultView {
  MONTH_RESULTS_VIEW = 'MONTH_RESULTS_VIEW',
  WEEK_RESULTS_VIEW = 'WEEK_RESULTS_VIEW',
  DAY_RESULTS_VIEW = 'DAY_RESULTS_VIEW',
}

export const RESULT_VIEWS = [
  ResultView.DAY_RESULTS_VIEW,
  ResultView.WEEK_RESULTS_VIEW,
  ResultView.MONTH_RESULTS_VIEW,
];

// Result time periods
export enum ResultPeriod {
  MONTH_RESULTS_PERIOD = 'MONTH_RESULTS_PERIOD',
  WEEK_RESULTS_PERIOD = 'WEEK_RESULTS_PERIOD',
  DAY_RESULTS_PERIOD = 'DAY_RESULTS_PERIOD',
  NO_PERIOD = 'NO_PERIOD',
}

export const RESULT_PERIODS = [
  ResultPeriod.DAY_RESULTS_PERIOD,
  ResultPeriod.WEEK_RESULTS_PERIOD,
  ResultPeriod.MONTH_RESULTS_PERIOD,
  ResultPeriod.NO_PERIOD,
];

// Charts
export enum ChartType {
  BAR_CHART = 'BAR_CHART',
  LINE_CHART = 'LINE_CHART',
}

// Forms
export const EDIT_SURVEY_FORM_NAME = 'EDIT_SURVEY';
export const ADD_SURVEY_FORM_NAME = 'ADD_SURVEY';

export const CURRENT_DATE = new Date();

export const MONTHS: Record<string, Array<string>> = {
  de: MONTHS_LONG_NAME,
};

export const WEEKDAYS_LONG: Record<string, Array<string>> = {
  de: WEEKDAYS_LONG_NAME,
};

export const WEEKDAYS_SHORT: Record<string, Array<string>> = {
  de: WEEKDAYS_SHORT_NAME,
};

export const FIRST_DAY: Record<string, number> = {
  de: 1, // Monday
};

export enum SelectedConditional {
  LINK = 'LINK',
  QUESTION = 'QUESTION',
  NONE = 'NONE',
}
