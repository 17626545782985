/* eslint-disable react/display-name */
import { getColors } from '@gts-ns/ui';
import { AnswerTypeType } from '@gts-ns/utils';

import { ListItemText } from '@material-ui/core';
import {
  faFrown,
  faMeh,
  faSmile,
  faThumbsUp,
  faThumbsDown,
  faStar,
} from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const smileys = [faSmile, faMeh, faFrown];
const thumbs = [faThumbsDown, faThumbsUp];

export function getLegendEntries(
  answerType: AnswerTypeType,
  labels: Array<string>,
  dataKeyStates: Record<string, boolean>,
) {
  const colors = getColors(answerType, labels.length);
  switch (answerType) {
    case AnswerTypeType.SMILEYS:
      return labels.map((label, index) => ({
        LabelComponent: () => (
          <FontAwesomeIcon size="2x" icon={smileys[index]} />
        ),
        color: colors(index),
        state: dataKeyStates[label],
        label,
      }));
    case AnswerTypeType.THUMBS:
      return labels.map((label, index) => ({
        LabelComponent: () => (
          <FontAwesomeIcon size="2x" icon={thumbs[index]} />
        ),
        color: colors(index),
        state: dataKeyStates[label],
        label,
      }));
    case AnswerTypeType.STARS:
      return labels.map((label, index) => ({
        LabelComponent: () => (
          <>
            {[0, 1, 2, 3, 4].map(
              (i) =>
                i <= index && (
                  <FontAwesomeIcon
                    size="2x"
                    key={`${i} ${label}`}
                    icon={faStar}
                  />
                ),
            )}
          </>
        ),
        color: colors(index),
        state: dataKeyStates[label],
        label,
      }));
    default:
      return labels.map((label, index) => ({
        LabelComponent: () => <ListItemText primary={label} />,
        color: colors(index),
        state: dataKeyStates[label],
        label,
      }));
  }
}
