import { EditConditionals, EditSurvey } from '../../types';
import { SelectedConditional } from '../../constants';

export function getConditionalsFromAnswers(answers: EditSurvey['answers']) {
  return answers.reduce<EditConditionals>(
    (prev, cur) => {
      if (
        cur.selectedConditional === SelectedConditional.QUESTION &&
        cur.conditionalQuestion !== null
      ) {
        return {
          ...prev,
          questions: [
            ...prev.questions,
            {
              question: cur.conditionalQuestion,
              answerTypeAnswerId: cur.answerTypeAnswerId,
            },
          ],
        };
      } else if (
        cur.selectedConditional === SelectedConditional.LINK &&
        cur.conditionalLink !== null
      ) {
        return {
          ...prev,
          links: [
            ...prev.links,
            {
              link: cur.conditionalLink,
              answerTypeAnswerId: cur.answerTypeAnswerId,
            },
          ],
        };
      } else {
        return prev;
      }
    },
    { questions: [], links: [] },
  );
}
