import { connect } from 'react-redux';

import { ThunkDispatch } from 'redux-thunk';
import { toggleDrawer, execChangePeriodType, changeView } from '../actions';

import { SurveyResultsDrawer } from '../components/SurveyResults/SurveyResultsDrawer';
import { changePrimaryChartType } from '../actions/surveyResults/changePrimaryChartType';
import { toggleSecondaryChart } from '../actions/surveyResults/toggleSecondaryChart';
import { ReduxState } from '../types';
import { Actions } from '../actions/reduxActionTypes';
import { ChartType, ResultPeriod, ResultView } from '../constants';

const mapStateToProps = (state: ReduxState) => {
  const { surveyResults } = state;
  const {
    view,
    period: { type },
    drawerOpen,
    primaryChartType,
    showSecondaryChart,
  } = surveyResults;

  return {
    periodType: type,
    view,
    open: drawerOpen,
    primaryChartType,
    showSecondaryChart,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ReduxState, undefined, Actions>,
) => ({
  toggleDrawer(isOpen: boolean) {
    dispatch(toggleDrawer(isOpen));
  },
  execChangePeriodType(newType: ResultPeriod, newView: ResultView) {
    dispatch(execChangePeriodType(newType, newView));
  },
  execChangeView(newView: ResultView) {
    dispatch(changeView(newView));
  },
  execChangePrimaryChartType(primaryChartType: ChartType) {
    dispatch(changePrimaryChartType(primaryChartType));
  },
  execToggleSecondaryChart(state: boolean) {
    dispatch(toggleSecondaryChart(state));
  },
});

export const SurveyResultsDrawerContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SurveyResultsDrawer);
