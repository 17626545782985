import { ID_REGEX } from '@gts-common/client-server';
import { CustomAnswerTypeAnswer, Question } from '../../types';

// question could be a conditional question or a survey
// but we only care about the question part of the survey
export function prepareQuestionForServer(
  question: Question,
  isCustom: boolean,
) {
  const { answers } = question;
  if (isCustom) {
    // When isCustom is true the answers must be an array
    const answersForServer = (answers as Array<CustomAnswerTypeAnswer>).map(
      ({ answerTypeAnswerId, label }) => {
        const trimmedLabel = label.trim();
        // When an answer is added by the client we use dummy IDs to be able to distinguish the answers in the list.
        // If the id doesn't match our ID_REGEX then it is a dummy ID for add and needs to be removed
        return ID_REGEX.test(answerTypeAnswerId)
          ? { label: trimmedLabel, answerTypeAnswerId }
          : { label: trimmedLabel };
      },
    );

    return {
      question: question.question.trim(),
      description: question.description.trim(),
      answers: answersForServer,
      answerTypeId: undefined,
    };
  } else {
    return {
      question: question.question.trim(),
      description: question.description.trim(),
      answerTypeId: question.answerTypeId,
      answers: undefined,
    };
  }
}
