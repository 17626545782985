import { connect } from 'react-redux';

import { ThunkDispatch } from 'redux-thunk';
import { Legend } from '../components/SurveyResults/Legend';
import { updateDataKeyState } from '../actions/surveyResults/updateDataKeyState';
import { ReduxState } from '../types';

import { Actions } from '../actions/reduxActionTypes';
import { getLegendEntries } from './helpers/getLegendEntries';

const mapStateToProps = (state: ReduxState) => {
  const questions = state.surveyResults.questions;

  if (!questions) {
    throw new Error('Questions is undefined');
  }

  const { selectedQuestionTab } = state.surveyResults;
  const { answerType, labels, dataKeyStates } = questions[selectedQuestionTab];

  const maxLength = 6;
  const entries = getLegendEntries(answerType, labels, dataKeyStates);

  let leftEntries: typeof entries;
  let rightEntries: typeof entries;

  const hasTwoColumns = entries.length >= maxLength || entries.length === 2;
  if (hasTwoColumns) {
    const breakpoint = Math.ceil(entries.length / 2);
    leftEntries = entries.slice(0, breakpoint);
    rightEntries = entries.slice(breakpoint);
  } else {
    leftEntries = entries;
    rightEntries = [];
  }

  return { hasTwoColumns, leftEntries, rightEntries };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ReduxState, undefined, Actions>,
) => ({
  execUpdateDataKeyState(updates: Record<string, boolean>) {
    dispatch(updateDataKeyState(updates));
  },
});

export const LegendContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Legend);
