import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Line,
  LineChart,
} from 'recharts';
import { AnswerTypeType } from '@gts-ns/utils';
import { PrimaryTooltip } from './helpers/Tooltips';

interface Props {
  answerType: AnswerTypeType;
  answers: Array<{ label: string }>;
  dataKeys: Array<string>;
  colors: (num: number) => string;
  dataKeyStates: Record<string, boolean>;
}

export const CustomLineChart = ({
  answers,
  dataKeys,
  answerType,
  colors,
  dataKeyStates,
}: Props) => {
  return (
    <ResponsiveContainer aspect={2 / 1}>
      <LineChart data={answers}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="label" />
        <YAxis />
        <Tooltip content={<PrimaryTooltip colors={colors} />} />
        {dataKeys.map(
          (dataKey, index) =>
            dataKeyStates[dataKey] && (
              <Line
                dataKey={dataKey}
                isAnimationActive={false}
                key={`${answerType} ${dataKey}`}
                stroke={colors(index)}
                strokeWidth={4}
                type="monotone"
                dot={false}
                activeDot
              />
            ),
        )}
      </LineChart>
    </ResponsiveContainer>
  );
};
