import { WarningText } from '@gts-common/client';

export function SurveyNotPendingWarningPanel() {
  return (
    <WarningText>
      Diese Umfrage wurde bereits aktiviert. Es können daher keine weiteren
      Nachfragen und Links angelegt werden.
    </WarningText>
  );
}
