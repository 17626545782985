import {
  MAX_NAME_LENGTH,
  MAX_QUESTION_LENGTH,
  MAX_DESCRIPTION_LENGTH,
  MAX_CUSTOM_ANSWER_LENGTH,
  MAX_THANK_YOU_TEXT_LENGTH,
  MAX_LINK_HEADLINE_LENGTH,
  MAX_LINK_DESCRIPTOR_LENGTH,
  MAX_LINK_URL_LENGTH,
  MAX_LINK_URL_DESCRIPTOR_LENGTH,
} from '@gts-ns/utils';
import { ID_REGEX } from '@gts-common/client-server';
import * as yup from 'yup';
import { ArraySchema } from 'yup';
import {
  getMaxLengthValidationMessage,
  requiredFieldValidationMessage,
} from '@gts-common/client';
import { SelectedConditional } from './constants';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function uniqueCustomAnswer(this: ArraySchema<any>, message: string) {
  return this.test({
    name: 'uniqueCustomAnswer',
    exclusive: false,
    message,
    test(answers) {
      if (answers !== undefined && answers.length > 0) {
        const customAnswers = answers.map((answer) => answer.label);
        const uniqueAnswers = new Set(customAnswers);

        // If duplicates are found then the Set contains less elements than the array
        return uniqueAnswers.size === customAnswers.length;
      } else {
        // Don't fail if the array is empty or undefined
        return true;
      }
    },
  });
}

yup.addMethod(yup.array, 'uniqueCustomAnswer', uniqueCustomAnswer);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TrueArraySchema = ArraySchema<any> & {
  uniqueCustomAnswer: typeof uniqueCustomAnswer;
};

const baseQuestionValidation = {
  answerTypeId: yup.string().matches(ID_REGEX),
  question: yup
    .string()
    .required(requiredFieldValidationMessage)
    .trim()
    .max(
      MAX_QUESTION_LENGTH,
      getMaxLengthValidationMessage(MAX_QUESTION_LENGTH),
    ),
  description: yup
    .string()
    .trim()
    .max(
      MAX_DESCRIPTION_LENGTH,
      getMaxLengthValidationMessage(MAX_DESCRIPTION_LENGTH),
    ),
};

const customAnswersValidation = (yup.array().of(
  yup.object({
    label: yup
      .string()
      .trim()
      .max(
        MAX_CUSTOM_ANSWER_LENGTH,
        getMaxLengthValidationMessage(MAX_CUSTOM_ANSWER_LENGTH),
      )
      .required(requiredFieldValidationMessage),
  }),
) as TrueArraySchema).uniqueCustomAnswer(
  'Antworten müssen sich voneinander unterscheiden',
);

const linkValidation = {
  headline: yup
    .string()
    .trim()
    .max(
      MAX_LINK_HEADLINE_LENGTH,
      getMaxLengthValidationMessage(MAX_LINK_HEADLINE_LENGTH),
    )
    .required(requiredFieldValidationMessage),
  description: yup
    .string()
    .trim()
    .max(
      MAX_LINK_DESCRIPTOR_LENGTH,
      getMaxLengthValidationMessage(MAX_LINK_DESCRIPTOR_LENGTH),
    ),
  urlDescription: yup
    .string()
    .trim()
    .max(
      MAX_LINK_URL_DESCRIPTOR_LENGTH,
      getMaxLengthValidationMessage(MAX_LINK_URL_DESCRIPTOR_LENGTH),
    )
    .required(requiredFieldValidationMessage),
  url: yup
    .string()
    .trim()
    .max(
      MAX_LINK_URL_LENGTH,
      getMaxLengthValidationMessage(MAX_LINK_URL_LENGTH),
    )
    .required(requiredFieldValidationMessage)
    .url('Ungültige URL'),
};

const conditionalQuestionValidation = (customAnswerTypeId: string) => ({
  ...baseQuestionValidation,
  answers: yup.array().when('answerTypeId', {
    is: (value: string) => value === customAnswerTypeId,
    then: customAnswersValidation,
  }),
});

const questionValidation = (customAnswerTypeId: string) => ({
  ...baseQuestionValidation,
  answers: yup.array().when('answerTypeId', {
    is: (value: string) => value === customAnswerTypeId,
    then: customAnswersValidation,
    otherwise: yup.array().of(
      yup.object({
        selectedConditional: yup
          .string()
          .oneOf([
            SelectedConditional.NONE,
            SelectedConditional.QUESTION,
            SelectedConditional.LINK,
          ]),
        conditionalQuestion: yup.object().when('selectedConditional', {
          is: SelectedConditional.QUESTION,
          then: yup.object(conditionalQuestionValidation(customAnswerTypeId)),
        }),
        conditionalLink: yup.object().when('selectedConditional', {
          is: SelectedConditional.LINK,
          then: yup.object(linkValidation),
        }),
      }),
    ),
  }),
});

const addSurveyVal = (customAnswerId: string) => ({
  name: yup
    .string()
    .trim()
    .required(requiredFieldValidationMessage)
    .max(MAX_NAME_LENGTH, getMaxLengthValidationMessage(MAX_NAME_LENGTH)),
  ...questionValidation(customAnswerId),
  thankYouText: yup
    .string()
    .trim()
    .required(requiredFieldValidationMessage)
    .max(
      MAX_THANK_YOU_TEXT_LENGTH,
      getMaxLengthValidationMessage(MAX_THANK_YOU_TEXT_LENGTH),
    ),
});

const editSurveyVal = (customAnswerId: string) => addSurveyVal(customAnswerId);

export const addSurveyValidation = (customAnswerId: string) =>
  yup.object(addSurveyVal(customAnswerId));
export const editSurveyValidation = (customAnswerId: string) =>
  yup.object(editSurveyVal(customAnswerId));
