import { Route, Switch } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { LinkButton, InfoText } from '@gts-common/client';
import {
  HOME_VIEW,
  SURVEY_ADD_VIEW,
  SURVEY_EDIT_VIEW,
  SURVEY_QR_VIEW,
  SURVEY_RESULTS_VIEW,
} from '../constants';
import { HomeContainer } from '../containers/Home';
import { SurveyEditViewContainer } from '../containers/SurveyEditView';
import { SurveyAddContainer } from '../containers/SurveyAdd';
import { SurveyResultsViewContainer } from '../containers/SurveyResultsView';
import { SurveyQrContainer } from '../containers/SurveyQr';

export const MainRouter = () => {
  return (
    <Switch>
      <Route path={SURVEY_ADD_VIEW}>
        <SurveyAddContainer />
      </Route>
      <Route path={SURVEY_EDIT_VIEW}>
        <SurveyEditViewContainer />
      </Route>
      <Route path={SURVEY_RESULTS_VIEW}>
        <SurveyResultsViewContainer />
      </Route>
      <Route path={SURVEY_QR_VIEW}>
        <SurveyQrContainer />
      </Route>
      <Route path={HOME_VIEW} exact={true}>
        <HomeContainer />
      </Route>
      <Route path="*">
        <Box textAlign="center" mt="4rem">
          <InfoText>Pfad nicht gefunden.</InfoText>
          <Box mt="3rem">
            <LinkButton to={HOME_VIEW} label="Zur Startseite" />
          </Box>
        </Box>
      </Route>
    </Switch>
  );
};
