import {
  createStyles,
  Tab,
  Tabs,
  Theme,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import { AnswerTypeType } from '@gts-ns/utils';
import { getAnswerTypeTabProps } from '../../helpers/getAnswerTypeTabProps';
import { AnswerTypeAnswer } from '../../../types';

const styles = (theme: Theme) =>
  createStyles({
    icon: {
      [theme.breakpoints.down('xs')]: {
        fontSize: 20,
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: 30,
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 40,
      },
    },
    stars: {
      [theme.breakpoints.down('xs')]: {
        fontSize: 8,
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: 21,
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 28,
      },
    },
  });

interface SurveyResultTabs extends WithStyles<typeof styles> {
  answerType: AnswerTypeType;
  answersWithQuestion: Array<AnswerTypeAnswer & { answerIndex: number }>;
  value: number;
  setValue: (num: number) => void;
}

function UnstyledSurveyResultsTabs({
  answerType,
  answersWithQuestion,
  value,
  setValue,
  classes,
}: SurveyResultTabs) {
  return (
    <Tabs
      TabIndicatorProps={{
        style: {
          height: '0.35rem',
        },
      }}
      variant={answersWithQuestion.length > 7 ? 'scrollable' : 'fullWidth'}
      value={value}
      onChange={(_event, newValue) => setValue(newValue)}
    >
      <Tab label="Hauptfrage" />
      {answersWithQuestion.map(({ answerIndex, ...answer }) => (
        <Tab
          key={answerIndex}
          {...getAnswerTypeTabProps(
            answerType,
            answerIndex,
            answer.label,
            classes,
          )}
        />
      ))}
    </Tabs>
  );
}

export const SurveyResultsTabs = withStyles(styles)(UnstyledSurveyResultsTabs);
