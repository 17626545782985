import { connect } from 'react-redux';

import { createMatchSelector, RouterRootState } from 'connected-react-router';
import { ReduxState } from '../types';
import { SurveyQR } from '../components/Surveys/SurveyQR';
import { SURVEY_QR_VIEW } from '../constants';

const matchSelector = createMatchSelector<
  RouterRootState,
  { surveyId: string }
>(SURVEY_QR_VIEW);

const mapStateToProps = (state: ReduxState) => {
  const match = matchSelector(state);
  const surveyId = match ? match.params.surveyId : undefined;

  const selectedSurvey = state.surveys.surveys.find(
    (survey) => survey.surveyId === surveyId,
  );

  if (selectedSurvey) {
    return { survey: selectedSurvey };
  } else {
    return { survey: undefined };
  }
};

export const SurveyQrContainer = connect(mapStateToProps)(SurveyQR);
