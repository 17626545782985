import {
  AppBar,
  Grid,
  IconButton,
  makeStyles,
  Toolbar,
  Box,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { Logo } from '@gts-common/client';
import PersonIcon from '@material-ui/icons/Person';
import { MouseEvent } from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import logoSrc from '../../images/logo.png';
import { HOME_VIEW } from '../../constants';

const useStyles = makeStyles({
  root: {
    marginBottom: '20px',
  },
});

interface HeaderAppBarProps {
  showMenuIcon: boolean;
  openAccountMenu: (event: MouseEvent) => void;
  toggleDrawer: () => void;
}

export const HeaderAppBar = ({
  showMenuIcon,
  openAccountMenu,
  toggleDrawer,
}: HeaderAppBarProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const isXsOrSmaller = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <AppBar position="static" color="default" classes={classes}>
      <Toolbar>
        <Grid container spacing={2} alignItems="center" justify="center">
          {/* Drawer */}
          <Grid item xs={4} sm={3} md={2}>
            <Box display={showMenuIcon ? 'block' : 'none'}>
              <IconButton onClick={toggleDrawer}>
                <MenuIcon />
              </IconButton>
            </Box>
          </Grid>
          {/* Logo */}
          <Grid item xs={4} sm={6} md={8} style={{ textAlign: 'center' }}>
            <Box display="flex" justifyContent="center">
              <Logo
                to={HOME_VIEW}
                src={logoSrc}
                title="next-survey"
                hideText={isXsOrSmaller}
              />
            </Box>
          </Grid>
          {/* IconButtons */}
          <Grid item xs={4} sm={3} md={2}>
            <Box textAlign="right">
              <IconButton
                onClick={openAccountMenu}
                color="inherit"
                size="small"
              >
                <PersonIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
