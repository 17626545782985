import { Formik } from 'formik';

import { AnswerTypeType, SurveyStatus } from '@gts-ns/utils';
import { Box, IconButton } from '@material-ui/core';
import { BarChart, Link as LinkIcon } from '@material-ui/icons';
import { SaveButton, SelectOption } from '@gts-common/client';
import { EDIT_SURVEY_FORM_NAME } from '../../constants';
import { editSurveyValidation } from '../../validationSchemata';
import { EditSurvey } from '../../types';
import { SurveyForm } from './SurveyForm';

import { Conditionals } from './Conditionals/Conditionals';

interface Props {
  survey: EditSurvey;
  selectAnswerTypeOptions: Array<SelectOption>;
  widgetUrl: string;
  answerTypeType: AnswerTypeType;
  customAnswerTypeId: string;
  showSurveyResults: (surveyId: string) => void;
  execEditSurvey: (surveyId: string, surveyUpdate: EditSurvey) => void;
}

export const SurveyEdit = ({
  survey,
  selectAnswerTypeOptions,
  widgetUrl,
  answerTypeType,
  customAnswerTypeId,
  showSurveyResults,
  execEditSurvey,
}: Props) => {
  return (
    <>
      <Box>
        <IconButton
          href={widgetUrl}
          target="_blank"
          disabled={survey.status !== SurveyStatus.ACTIVE}
        >
          <LinkIcon href={widgetUrl} />
        </IconButton>
        <IconButton
          disabled={survey.status === SurveyStatus.PENDING}
          onClick={() => showSurveyResults(survey.surveyId)}
        >
          <BarChart />
        </IconButton>
      </Box>
      <Formik
        onSubmit={(values: EditSurvey) => {
          execEditSurvey(survey.surveyId, values);
        }}
        initialValues={survey}
        validationSchema={editSurveyValidation(customAnswerTypeId)}
      >
        {({ values, setTouched, errors }) => {
          return (
            <SurveyForm
              selectAnswerTypeOptions={selectAnswerTypeOptions}
              isEdit={true}
              isPending={survey.status === SurveyStatus.PENDING}
              formId={EDIT_SURVEY_FORM_NAME}
              customAnswerTypeId={customAnswerTypeId}
            >
              {!survey.isCustom && (
                <Conditionals
                  mainQuestionId={survey.questionId}
                  surveyStatus={survey.status}
                  customAnswerTypeId={customAnswerTypeId}
                  selectAnswerTypeOptions={selectAnswerTypeOptions}
                  answerTypeType={answerTypeType}
                  formikAnswers={values.answers}
                  setTouched={setTouched}
                  errors={errors}
                />
              )}
            </SurveyForm>
          );
        }}
      </Formik>
      <Box mt="1rem" textAlign="center">
        <SaveButton submitForForm={EDIT_SURVEY_FORM_NAME} />
      </Box>
    </>
  );
};
