import {
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  Cell,
  PieLabelRenderProps,
} from 'recharts';
import { PieTooltip } from './helpers/Tooltips';

const RADIAN = Math.PI / 180;

const PieLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}: PieLabelRenderProps) => {
  const radius =
    Number(innerRadius) + (Number(outerRadius) - Number(innerRadius)) * 1.06;
  const x = Number(cx) + radius * Math.cos(-Number(midAngle) * RADIAN);
  const y = Number(cy) + radius * Math.sin(-Number(midAngle) * RADIAN);

  return (
    <text
      x={x}
      y={y}
      textAnchor={x > Number(cx) ? 'start' : 'end'}
      dominantBaseline="central"
    >
      {`${(Number(percent) * 100).toFixed(2)}%`}
    </text>
  );
};

interface Props {
  answers: Array<{ label: string }>;
  colors: (num: number) => string;
}

export const CustomPieChart = ({ answers, colors }: Props) => {
  return (
    <ResponsiveContainer aspect={1.3}>
      <PieChart data={answers}>
        <Tooltip content={<PieTooltip />} />
        <Pie
          isAnimationActive={false}
          data={answers}
          nameKey="label"
          dataKey="value"
          label={PieLabel}
          labelLine={false}
        >
          {answers.map((entry, index) => (
            <Cell
              key={entry.label}
              fill={colors(index)}
              stroke="black"
              strokeWidth={0.2}
            />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  );
};
